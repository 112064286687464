import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuizView from './QuizView'

import * as quizesActions from '../../../../../store/actions/quizes'

export default connect(
  state => ({
    quizes: state.quizes,
    user: state.user,
    isEnableModal: state.quizes.isEnableModal
  }),
  dispatch => ({
    quizesActions: bindActionCreators(quizesActions, dispatch)
  })
)(QuizView)
