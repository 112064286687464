import { Progress } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useEffect, useMemo, useState } from 'react';

import { Unity, useUnityContext } from 'react-unity-webgl-latest';
import { config } from '../../../../../../../constants';

const SimulationUnityView = ({
  unityUrl,
  simulationId,
  userId,
  token,
  ids,
  isEnableModal
}) => {
  const { loaderUrl, dataUrl, frameworkUrl, codeUrl } = unityUrl
  const { lessonId, courseId, pageId } = ids
  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    isLoaded,
    unload,
    loadingProgression,
    error: unityError,
  } = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl
  });

   const [unityUnloaded, setUnityUnloaded] = useState(false);

  const loadingPercentage = useMemo(
    () => Math.round(loadingProgression * 100),
    [loadingProgression]
  );

  const getAccessJwtToken = async () => {
    const session = await Auth.currentSession();
    const newToken = await session.idToken.jwtToken;
    return newToken;
  };

  const handleNewToken = async (msg) => {
    if (msg === 'token_needed') {
      const token = await getAccessJwtToken();
      sendMessage('Data', 'Get_Fresh_Token', String(token));
    }
  };

  const handleUnloadUnity = () => {
    if (isLoaded) {
      unload()
        .then(() => {
          console.log('Unity instance successfully unloaded.');
          setUnityUnloaded(true);
        })
        .catch((error) => {
          console.error('Error during Unity unload:', error);
        });
    } else {
      console.warn('Unity instance is not instantiated; skipping unload.');
    }
  };

  useEffect(() => {
    if (isLoaded) {
      sendMessage('Data', 'Get_courseid', String(courseId));
      sendMessage('Data', 'Get_userid', String(userId));
      sendMessage('Data', 'Get_simulationid', String(simulationId));
      sendMessage('Data', 'Get_token', String(token));
      sendMessage('Data', 'Get_baseurl', String(config.baseURL));
      sendMessage('Data', 'Get_lessonid', String(lessonId));
      sendMessage('Data', 'Get_pageid', String(pageId));
      addEventListener('GetToken', handleNewToken);
    }

    return () => {
      removeEventListener('GetToken', handleNewToken);
    };
  }, [isLoaded]);

  useEffect(() => {
    handleUnloadUnity(); 
  }, [isEnableModal]);

  return (
    <div className="quiz-unity-container">
      {/* {unityError && (
        <div className="unity-error-message">
          Error loading Unity instance: {unityError.message}
        </div>
      )} */}

      {/* {unityUnloaded && (
        <div className="unity-unloaded-message">
          Unity instance has been unloaded.
        </div>
      )} */}

      {isLoaded === false && !unityError && (
        <div className="quiz-unity-loading-overlay">
          <Progress type="circle" percent={loadingPercentage} />
          Loading Simulation...
        </div>
      )}

      {!unityUnloaded && (
        <Unity
          className="quiz-unity"
          unityProvider={unityProvider}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  );
};

export default SimulationUnityView;
