import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './Discussion.scss'
import { DiscussionContainer } from './styled'
import DiscussionForm from './Form'
import Message from './Message'
import { Skeleton, Empty } from 'antd'
class Discussion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
      activeTab: ''
    }
  }
  componentDidMount() {
    const discussionByEvent = this.props.discussion.discussionByEvent
      ? this.props.discussion.discussionByEvent
      : []
    if (discussionByEvent && discussionByEvent.length > 0) return
    this.props.getDiscussions(
      this.props.discussionId,
      this.props.discussionType
    )
  }

  handleOutsideClick = event => {
    this.setState({
      clicked: true
    })
  }

  onClickTabItem = tab => {
    if (tab !== this.state.activeTab) {
      this.setState({ activeTab: tab })
    } else {
      this.setState({ activeTab: '' })
    }
  }

  render() {
    const { activeTab } = this.state
    const {
      userId,
      discussionId,
      discussionType,
      isFetching,
      isDiscussionDisabled
    } = this.props
    const discussionByEvent = this.props.discussion.discussionByEvent
      ? this.props.discussion.discussionByEvent
      : []
    const { postingComment } = this.props.discussion
    return (
      <DiscussionContainer>
        <DiscussionForm
          isDisabled={isDiscussionDisabled}
          discussionId={discussionId}
          discussionType={discussionType}
        />
        <DiscussionContainer.Overlay onClick={this.handleOutsideClick} />
        {!isFetching &&
          discussionByEvent.length > 0 &&
          discussionByEvent.map(message => (
            <Message
              discussionId={discussionId}
              discussionType={discussionType}
              message={message}
              currentUser={userId}
              key={message.id}
              isRoot
              getDiscussions={this.props.getDiscussions}
              activeTab={activeTab}
              onClickTabItem={this.onClickTabItem}
            />
          ))}
        {(isFetching || postingComment) && (
          <Skeleton paragraph={{ rows: postingComment ? 2 : 5 }} active />
        )}
        {!isFetching && discussionByEvent.length === 0 && (
          <Empty description={'No Data Found.'} />
        )}
      </DiscussionContainer>
    )
  }
}

export default withTranslation()(Discussion)
