import { Icon } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import MyRosterCell from '../../../AmazonChime/MyRosterCell'
import dummyUserImage from '../../../../assets/user.svg'
import './EventParticipants.scss'
import PassiveUsers from '../../../PassiveUsers'
import { withTranslation } from 'react-i18next'
import micOn from '../../../../assets/mic_on.svg'
import micOff from '../../../../assets/mic_off.svg'
import cameraOff from '../../../../assets/camera_off.svg'
import cameraOn from '../../../../assets/camera_on.svg'
import { events } from '../../../../../../../components/Navigation/images'

function EventParticipants(props) {
  const [waitingList, setWaitingList] = useState([])
  const [joinedList, setJoinedList] = useState([])
  const [others, setOthers] = useState([])

  const {
    participantData: { attendees = [] },
    onInvite,
    event,
    event: { hosts = [], host = {} },
    userId,
    allParticipants: participants = [],
    participantSize,
    participants: passiveParticipants = [],
    raiseHandAllowed,
    handleDecline,
    handleAllow,
    showPassiveUsers,
    previewStreamList,
    previewLocalStream,
    t
  } = props

  const inRequestPassiveParticipants = useMemo(
    () => passiveParticipants.filter(u => u.status !== 'declined'),
    [passiveParticipants.length]
  )

  const isChime = useMemo(() => {
    return event && event.encoder !== 'agora_conference'
  }, [event])

  useEffect(() => {
    const { newWaitingList, newJoinedList } = computeParticipantLists(
      participants,
      event.host,
      attendees,
      isChime
    )

    setWaitingList(newWaitingList)
    setJoinedList(newJoinedList)
  }, [participants.length, event.host, attendees.length, isChime])

  useEffect(() => {
    if (isChime) {
      const othersArray = computeOthersArrayForChime(
        attendees,
        inRequestPassiveParticipants
      )
      setOthers(othersArray)
    } else {
      const othersArray = computeOthersArrayForAgora(
        attendees,
        hosts,
        host,
        inRequestPassiveParticipants
      )
      setOthers(othersArray)
    }
  }, [isChime, attendees.length, inRequestPassiveParticipants, hosts, host])

  const isCoaching = event.encoder === 'peer2peer'
  const isHost = event.host && event.host.id === userId

  const localAgoraUser = previewLocalStream && previewLocalStream.pc

  return (
    <div className="event__participants">
      <div className="event__participants_items">
        {joinedList.length > 0 && (
          <>
            <div className="event__participants_count">
              {joinedList.length} {t('v2:people')}
            </div>
            <div className="event__participants_users">
              {joinedList.map((user, index) => (
                <div className="event__participants_users_item" key={index}>
                  <div className="event__participants_users_item_about">
                    <img
                      alt=""
                      src={user.avatar ? user.avatar.link : dummyUserImage}
                    />
                    <span>{user.name || user.username}</span>
                  </div>
                  <div className="event__participants_users_item_status">
                    {isChime ? (
                      <MyRosterCell
                        key={user.chimeAttendeeId}
                        attendeeId={user.chimeAttendeeId}
                        isParticipants
                      />
                    ) : (
                      <>
                        {user.id === previewLocalStream.streamId && (
                          <>
                            <img
                              alt=""
                              src={
                                localAgoraUser && localAgoraUser.isAudioMute
                                  ? micOff
                                  : micOn
                              }
                            />
                            <img
                              alt=""
                              src={
                                localAgoraUser && localAgoraUser.isVideoMute
                                  ? cameraOff
                                  : cameraOn
                              }
                            />
                          </>
                        )}
                        {previewStreamList.map((item, index) => {
                          const agoraId = item.streamId
                          const specificId = agoraId === user.id
                          return (
                            specificId && (
                              <>
                                <img
                                  alt=""
                                  src={item.peerMuteAudio ? micOff : micOn}
                                />
                                <img
                                  alt=""
                                  src={
                                    item.peerMuteVideo ? cameraOff : cameraOn
                                  }
                                />
                              </>
                            )
                          )
                        })}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {waitingList.length > 0 && (
          <ParticipantItems
            t={t}
            array={waitingList}
            status={t('v2:waiting')}
          />
        )}
        {inRequestPassiveParticipants &&
          inRequestPassiveParticipants.length > 0 && (
            <>
              <div className="event__participants_count">
                {t('v2:hand_raising_users')} -{' '}
                {inRequestPassiveParticipants.length} {t('v2:people')}
              </div>
              <div className="event__participants_users">
                <PassiveUsers
                  isHost={showPassiveUsers}
                  participantSize={participantSize}
                  participants={passiveParticipants}
                  handleAllow={userId => handleAllow(userId)}
                  handleDecline={userId => handleDecline(userId)}
                  raiseHandAllowed={raiseHandAllowed}
                />
              </div>
            </>
          )}
        {others.length > 0 && (
          <ParticipantItems
            t={t}
            array={others}
            name={'OTHERS ON THIS CALL - '}
          />
        )}
      </div>
      {isHost && !isCoaching && (
        <div className="event__participants_invite" onClick={() => onInvite()}>
          <Icon type="usergroup-add" />
          {t('v2:invite')}
        </div>
      )}
    </div>
  )
}

const ParticipantItems = ({ array, name = '', status = '', t }) => (
  <>
    <div className="event__participants_count">
      {name} {array.length} {t('v2:people')}
    </div>
    <div className="event__participants_users">
      {array.map((user, index) => (
        <div
          className="event__participants_users_item"
          key={user.email + index}
        >
          <div className="event__participants_users_item_about">
            <img alt="" src={user.avatar ? user.avatar.link : dummyUserImage} />
            <span>
              {user.name ||
                user.username ||
                (typeof user === 'string' && user) ||
                user.email}
            </span>
          </div>
          <div className="event__participants_users_item_status">
            <span>{status}</span>
          </div>
        </div>
      ))}
    </div>
  </>
)

function computeParticipantLists(participants, host, attendees, isChime) {
  const newWaitingList = []
  const newJoinedList = []

  const userList = [...participants, host]
  userList.forEach(participant => {
    console.log("Test Processing Participant:", participant);

    const attendeeItem = isChime
      ? attendees.find(
        item => item.externalUserId.split('#')[1] === participant.id
      )
      : attendees.find(item => item.user_id === participant.id)
    console.log("Test Found Attendee Item:", attendeeItem);

    const newParticipant = attendeeItem
      ? isChime
        ? { ...participant, chimeAttendeeId: attendeeItem.chimeAttendeeId }
        : participant
      : null
    console.log("Test New Participant:", newParticipant);

    if (newParticipant) {
      newJoinedList.push(newParticipant)
    } else {
      newWaitingList.push(participant)
    }
  })

  return { newWaitingList, newJoinedList }
}

// Function to compute others array for Chime
function computeOthersArrayForChime(attendees, inRequestPassiveParticipants) {
  const others = attendees.filter(({ externalUserId }) => {
    const metaData = externalUserId.split('#')
    const name = metaData[0]
    const chimeUserId = metaData[1]

    const userRequestingHandRaise = inRequestPassiveParticipants.some(
      ({ userId }) => userId === chimeUserId
    )

    return (
      name.includes('passive') &&
      (!userRequestingHandRaise || userRequestingHandRaise.length === 0)
    )
  })

  const othersArrayOfNames = others.map(item => {
    const name = item.externalUserId.split('#')[0]
    return name.substring(7)
  })

  return othersArrayOfNames
}

// Function to compute others array for Non-Chime
function computeOthersArrayForAgora(
  attendees,
  hosts,
  host,
  inRequestPassiveParticipants
) {
  const filteredPassiveUsers = attendees.filter(({ user_id }) => {
    const isHostOrPresenter =
      hosts.some(host => host.id === user_id) || host.id === user_id
    const isRequestedPassiveUser = inRequestPassiveParticipants.some(
      item => item.userId === user_id
    )

    return !(isHostOrPresenter || isRequestedPassiveUser)
  })

  const othersArray = filteredPassiveUsers.map(item => ({
    avatar: { link: item.avatar },
    name: item.user_name
  }))

  return othersArray
}
export default withTranslation()(EventParticipants)
