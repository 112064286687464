import React, { Fragment, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import './styles.scss'
import _ from 'lodash'
import className from 'classnames'
import Dropzone from 'react-dropzone'
import { Button, Modal, Form, Tooltip, Popconfirm } from 'antd'

import { withTranslation } from 'react-i18next'
import { bytesToSize } from '../../utils'
import { general } from '../../constants'
import { PopupMedia } from '../../views/Library/internal'

import pdfImage from '../../assets/pdf_image.png'
import deleteIcon from '../../assets/bin.svg'
import attach_file from '../../assets/attach_file.svg'
import upload_attach from '../../assets/upload_attach.svg'
import ReactSVG from 'react-svg'

const FileUpload = ({ t, files = [], onFiles, upload }) => {
  const [showFileSelectModal, setShowFileSelectModal] = useState(false)
  const [showMediaLibrary, setShowMediaLibrary] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState(files)
  const [isUploading, setIsUploading] = useState(false)

  const library = useSelector(state => state.library)
  const { isFetching, uploadProgress, uploadSuccess } = library

  const onDropPdf = dropped => {
    const file = dropped[0]
    if (!file) {
      return
    }
    setShowFileSelectModal(false)
    setIsUploading(true)
    dropped.forEach((file, index) =>
      upload(file).then(res => {
        setUploadedFiles(files => [...files, res])
        if (index + 1 === dropped.length) {
          setIsUploading(false)
        }
      })
    )
  }

  const checkedCompletUpload = item => {
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  const handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const item = _.head(tempData.filter(value => value.isSelected))

    setUploadedFiles(files => [...files, item])
    setShowMediaLibrary(false)
    setShowFileSelectModal(false)
  }

  const deleteItem = id => {
    const remainingFiles = uploadedFiles.filter(item => item.id !== id)
    setUploadedFiles(remainingFiles)
  }

  useEffect(() => {
    if (files.length > 0 && files !== uploadedFiles) {
      setUploadedFiles(files)
    }
  }, [files])

  useEffect(() => {
    if (uploadedFiles !== files) {
      onFiles(uploadedFiles)
    }
  }, [uploadedFiles])

  const label =
    uploadedFiles && uploadedFiles.length > 0
      ? `${t('v3:attached_file')} ${uploadedFiles.length}`
      : t('v4:file_upload')

  return (
    <Fragment>
      <div className="file-upload__container">
        <Form.Item
          label={label}
          colon={false}
          required={false}
          style={{ marginBottom: '0' }}
        ></Form.Item>
        <div
          className={`file-upload-content ${uploadedFiles.length > 0 ? 'file-uploaded' : ''
            }`}
        >
          {/* {uploadedFiles.length > 0 && (
            <div className="file-upload__title">
              <Icon type="paper-clip" />
              <span>
                {t('v3:attached_file')} ({uploadedFiles.length})
              </span>
            </div>
          )} */}
          <div className="file-upload-inner">
            {uploadedFiles.length > 0 &&
              uploadedFiles.map(item => (
                <div className="file-upload__item">
                  <div className="file-upload__item-info">
                    <img
                      alt=""
                      className="file-upload__item-info-image"
                      src={pdfImage}
                    />
                    <Tooltip title={item.title}>
                      <span className="upload-pdf-title">{item.title}</span>
                    </Tooltip>
                  </div>
                  <div className="file-upload__item-action">
                    <span
                      onClick={() => window.open(item.link, '_blank').focus()}
                    >
                      {t('v3:view')}
                    </span>
                    <Popconfirm
                      icon={null}
                      title={t("v4:delete_file_confirm")}
                      onConfirm={() => deleteItem(item.id)}
                    >
                      <Tooltip
                        trigger={['hover']}
                        title={t("v4:delete_file_title")}
                      >
                        <img
                          alt=""
                          className="file-upload__item-action-delete"
                          src={deleteIcon}
                        />
                      </Tooltip>
                    </Popconfirm>

                  </div>
                </div>
              ))}
          </div>

          {uploadedFiles.length > 0 && (
            <Button
              className="file-upload__button file-upload-btn"
              type="dashed"
              loading={isFetching || isUploading}
              onClick={() => setShowFileSelectModal(true)}
            >
              <span className="attach-file-right">
                <ReactSVG src={upload_attach} />
              </span>
            </Button>
          )}
          {!uploadedFiles.length > 0 && (
            <Button
              className="file-upload__button"
              type="dashed"
              loading={isFetching || isUploading}
              onClick={() => setShowFileSelectModal(true)}
            >
              <span className="attach-file-left">
                <span className="attach-file">
                  <ReactSVG src={attach_file} />
                </span>
                <span className="attach_file-title">
                  <h2>{t('v3:attach_file')}</h2>
                  <p>{t('v3:attach_des')}</p>
                </span>
              </span>
              <span className="attach-file-right">
                <ReactSVG src={upload_attach} />
              </span>
            </Button>
          )}

          {showMediaLibrary && (
            <Modal
              visible={showMediaLibrary}
              onCancel={() => setShowMediaLibrary(false)}
              footer={null}
              width={'80%'}
              centered
            >
              <div className="wrap_modal_tabs">
                <PopupMedia
                  isPageBuilder
                  types="pdf"
                  handleInsertData={handleInsertData}
                />
              </div>
            </Modal>
          )}

          {showFileSelectModal && (
            <Modal
              title={t('v4:select_file_attach')}
              visible
              onCancel={() => setShowFileSelectModal(false)}
              className="custom-ant-modal"
              destroyOnClose
              width={600}
              footer={null}
              centered
            >
              <Dropzone accept={'.pdf'} onDrop={onDropPdf} style={{}} multiple>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={className(
                      'upload-dropzone',
                      'upload-dropzone_event',
                      {
                        'upload-dropzone_active': isDragActive
                      }
                    )}
                  >
                    <div className="wrap_upload_buttons">
                      <Button
                        className="rounded"
                        size="large"
                        icon="filepdfoutlined"
                      >
                        {t('v4:upload_pdf')}
                      </Button>
                      <Button
                        className="rounded"
                        size="large"
                        icon="filepdfoutlined"
                        onClick={e => {
                          e.stopPropagation()
                          setShowMediaLibrary(true)
                        }}
                      >
                        {t('buttons:select_media_library')}
                      </Button>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Modal>
          )}
        </div>
      </div>
      {uploadProgress.length > 0 &&
        uploadSuccess.length !== uploadProgress.length &&
        uploadProgress.map((item, ind) =>
          item.percent < 100 ? (
            <div key={item.uid} className="upload-modal__item">
              <div className="upload-modal-item">
                <div className="upload-modal-item__percent-border">
                  <div
                    className={`upload-modal-item__percent ${item.status === general.CANCELED
                      ? 'upload-modal-item__percent__cancel'
                      : ''
                      }`}
                    style={{ width: item.percent + '%' }}
                  />
                </div>
              </div>

              {item.status !== general.CANCELED && (
                <div className="upload-modal-item__weight">
                  {checkedCompletUpload(item) && (
                    <span>
                      {bytesToSize(item.loaded)} {t('of')}{' '}
                      {bytesToSize(item.total)}
                    </span>
                  )}
                  {!checkedCompletUpload(item) && (
                    <span className="upload-modal-item__weight__complete">
                      {uploadedFiles[ind] && uploadedFiles[ind].removed
                        ? t('general:removed')
                        : t('general:completed')}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : null
        )}
    </Fragment>
  )
}

export default withTranslation()(FileUpload)
