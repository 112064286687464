import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Icon,
  Upload,
  message,
  List,
  Typography,
  Tooltip,
  Checkbox,
  Spin,
  Alert,
  Result
} from 'antd'
import {
  CheckCircleOutlined,
  LoadingOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'

import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import './styles.scss'
import { api } from '../../../../../../services'
import { general } from '../../../../../../constants'
import { bytesToSize } from '../../../../../../utils'
import actions from '../../../../../../store/actions'
import history from '../../../../../../history'

const { Text } = Typography

const formFields = {
  end_goal: {
    value: 'End Goal',
    description: 'The ultimate objective or desired outcome of the scenario.'
  },
  problem_statement: {
    value: 'Problem Statement',
    description:
      'A concise description of the challenge or issue to be addressed in the scenario.'
  },
  learner_role: {
    value: 'Learner Role',
    description:
      'The specific role or position the learner is expected to assume within the scenario.'
  },
  depth: {
    value: 'Depth',
    description: 'The number of layers of scenarios within the metamaze'
  },
  no_of_options: {
    value: 'No of Options',
    description:
      'The number of alternative choices or paths available to the learner within the scenario.'
  }
}

const generationStatus = {
  in_progress: 'in_progress',
  completed: 'completed',
  error: 'error'
}

const AiMazeGeneration = ({
  form,
  onMazeGenerated,
  onClose,
  status: generatedStatus
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [fileList, setFileList] = useState([])
  const [fileError, setFileError] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [fileUploadStatus, setFileUploadStatus] = useState({})
  const [isMediaLibrarySelected, setIsMediaLibrarySelected] = useState(false)
  const [status, setStatus] = useState('')

  const dispatch = useDispatch()
  const library = useSelector(state => state.library)
  const user = useSelector(state => state.user)
  const maze = useSelector(state => state.mazeBuilder)
  const { uploadProgress, uploadSuccess } = library

  useEffect(() => {
    if (generatedStatus) setStatus(handleGenerationStatus(generatedStatus))

    if (generatedStatus === generationStatus.in_progress) {
      checkMazeStatus()
    }
  }, [generatedStatus])

  useEffect(() => {
    checkFileError()
  }, [fileList])

  const checkMazeStatus = () => {
    setStatus(generationStatus.in_progress)
    api.maze
      .getMazeById(maze.mazeId)
      .then(res => {
        const mazeObj = res.data.data
        if (mazeObj && mazeObj.generated_status === 'in_progress') {
          _.delay(checkMazeStatus, 20000)
        } else if (mazeObj && mazeObj.generated_status === 'completed') {
          onMazeGenerated()
          setStatus('')
        } else if (mazeObj && mazeObj.generated_status === 'error') {
          setStatus(generationStatus.error)
        }
      })
      .catch(error => {
        console.error('Error checking maze status:', error)
        setStatus(generationStatus.error)
      })
  }

  const handleOk = async e => {
    e.preventDefault()
    form.validateFields(async (errors, values) => {
      if (!isMediaLibrarySelected && fileError) return
      if (!errors) {
        try {
          if (isMediaLibrarySelected) {
            setIsGenerating(true)
            await generateMaze(values)
          } else {
            // First, upload the files
            setIsUploading(true)
            const newFiles = await uploadFiles(fileList)
            setIsUploading(false)

            // Then, generate the maze with the uploaded file IDs
            setIsGenerating(true)
            const files = newFiles.map(item => item.path)
            await generateMaze(values, files)
          }
        } catch (err) {
          console.log(err)
          message.error('An error occurred during the process.')
        } finally {
          setIsUploading(false)
          setIsGenerating(false)
        }
      }
    })
  }

  const uploadFiles = async files => {
    setUploadedFiles([])
    setFileUploadStatus({})
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      try {
        setFileUploadStatus(prevStatus => ({
          ...prevStatus,
          [file.name]: 'uploading'
        }))
        const res = await dispatch(
          actions.library.upload(file.originFileObj, {
            ai_language: 'en-US',
            isAiEnabled: true
          })
        )
        const newFiles = [...uploadedFiles, res]
        setUploadedFiles(newFiles)
        setFileUploadStatus(prevStatus => ({
          ...prevStatus,
          [file.name]: 'success'
        }))

        return newFiles
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error)
        message.error(`Failed to upload ${file.name}`)
        setFileUploadStatus(prevStatus => ({
          ...prevStatus,
          [file.name]: 'error'
        }))
        throw error
      }
    }
  }

  const generateMaze = async (values, fileList) => {
    const {
      end_goal,
      problem_statement,
      learner_role,
      depth,
      number_of_options
    } = values

    const { mazeId, mazeTitle } = maze

    const { active_organization_id, id, username, email } = user.info

    const payload = {
      end_goal,
      problem_statement,
      learner_role,
      depth,
      number_of_options,
      title: mazeTitle,
      programme_id: mazeId,
      user_id: id,
      email,
      username,
      org_id: active_organization_id,
      ...(!isMediaLibrarySelected && {
        file_list: fileList
      })
    }

    try {
      const res = await api.maze.generateMazeByAi(payload)
      if (res.data) {
        const status = res.data.status

        if (status === 'success') {
          checkMazeStatus()
        }
      }
    } catch (err) {
      console.log(err)
      message.error('An error occurred while generating the maze.')
      setStatus(generationStatus.error)
    }
  }

  const handleGenerationStatus = input => {
    const { in_progress, completed, error } = generationStatus
    const newStatus =
      input === 'in_progress'
        ? in_progress
        : input === 'completed'
        ? completed
        : error

    return newStatus
  }

  const checkFileError = () => {
    if (fileList.length === 0) {
      setFileError('Please upload at least one PDF file.')
    } else if (fileList.length > 5) {
      setFileError(
        'You can upload a maximum of 5 files. For more, please use the media library.'
      )
    } else {
      setFileError('')
    }
  }

  const handleFileChange = info => {
    let newFileList = [...info.fileList]

    // Only accept PDF files
    newFileList = newFileList.filter(file => {
      if (file.type !== 'application/pdf') {
        message.error(`${file.name} is not a PDF file`)
        return false
      }
      return true
    })

    setFileList(newFileList)
  }

  const { getFieldDecorator } = form

  const checkedCompletUpload = item => {
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  const handleModalClose = () => {
    const { mazeId } = maze

    if (status === generationStatus.in_progress || isGenerating) {
      dispatch(actions.maze.getMazeById(mazeId))
      history.push(`/maze/${mazeId}/edit`)
    } else {
      onClose()
    }
  }

  const renderFileStatus = () => {
    return (
      <List
        header="Upload File Status"
        itemLayout="horizontal"
        dataSource={fileList}
        renderItem={file => (
          <List.Item>
            <List.Item.Meta
              avatar={<Icon type="paper-clip" />}
              title={file.name}
              description={
                <>
                  {fileUploadStatus[file.name] === 'uploading' && (
                    <Text type="secondary">
                      <Icon type="loading" /> Uploading...
                    </Text>
                  )}
                  {fileUploadStatus[file.name] === 'success' && (
                    <Text type="success">
                      <Icon type="check-circle" /> Uploaded successfully
                    </Text>
                  )}
                  {fileUploadStatus[file.name] === 'error' && (
                    <Text type="danger">
                      <Icon type="close-circle" /> Upload failed
                    </Text>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />
    )
  }

  const GenerationFormView = () => {
    return (
      <Form layout="vertical" onSubmit={handleOk}>
        <div className="ai-maze-form-modal__body">
          {!isMediaLibrarySelected && (
            <>
              <Form.Item
                label="Upload Content (PDF files, max 5)"
                colon={false}
                required={true}
              >
                <Upload
                  multiple
                  accept=".pdf"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={() => false} // Prevent auto upload
                >
                  <Button>
                    <Icon type="upload" /> Select Files
                  </Button>
                </Upload>
                {fileError && (
                  <div style={{ color: 'red', marginTop: '8px' }}>
                    {fileError}
                  </div>
                )}
              </Form.Item>

              <b>OR</b>
            </>
          )}

          {/* check box for selection */}

          <Form.Item colon={false} required={false}>
            <Checkbox
              checked={isMediaLibrarySelected}
              onChange={() =>
                setIsMediaLibrarySelected(!isMediaLibrarySelected)
              }
            >
              <b className="ai-maze-form-label">
                Use Document from Organization Library
              </b>
            </Checkbox>
          </Form.Item>
        </div>

        {!isMediaLibrarySelected && (
          <div style={{ marginBottom: '20px' }}>
            {(isUploading || isGenerating) && renderFileStatus()}

            {uploadProgress.length > 0 &&
              uploadSuccess.length !== uploadProgress.length &&
              uploadProgress.map((item, ind) =>
                item.percent < 100 ? (
                  <div key={item.uid} className="upload-modal__item">
                    <div className="upload-modal-item">
                      <div className="upload-modal-item__percent-border">
                        <div
                          className={`upload-modal-item__percent ${
                            item.status === general.CANCELED
                              ? 'upload-modal-item__percent__cancel'
                              : ''
                          }`}
                          style={{ width: item.percent + '%' }}
                        />
                      </div>
                    </div>

                    {item.status !== general.CANCELED && (
                      <div className="upload-modal-item__weight">
                        {checkedCompletUpload(item) && (
                          <span>
                            {bytesToSize(item.loaded)} of{' '}
                            {bytesToSize(item.total)}
                          </span>
                        )}
                        {!checkedCompletUpload(item) && (
                          <span className="upload-modal-item__weight__complete">
                            {uploadedFiles[ind] && uploadedFiles[ind].removed
                              ? 'Removed'
                              : 'Completed'}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                ) : null
              )}
          </div>
        )}

        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.end_goal.value}</span>
              <Tooltip title={formFields.end_goal.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('end_goal', {
            rules: [{ required: true, message: 'Please input end goal' }]
          })(<Input.TextArea autoSize />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.problem_statement.value}</span>
              <Tooltip title={formFields.problem_statement.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('problem_statement', {
            rules: [
              { required: true, message: 'Please input problem statement' }
            ]
          })(<Input.TextArea autoSize />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.learner_role.value}</span>
              <Tooltip title={formFields.learner_role.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('learner_role', {
            rules: [{ required: true, message: 'Please input learner role' }]
          })(<Input.TextArea autoSize />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.depth.value}</span>
              <Tooltip title={formFields.depth.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('depth', {
            rules: [{ required: true, message: 'Please select depth' }],
            initialValue: 2 // Set default value
          })(
            <Select>
              {[2, 3, 4, 5, 6, 7, 8].map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.no_of_options.value}</span>
              <Tooltip title={formFields.no_of_options.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('number_of_options', {
            rules: [
              { required: true, message: 'Please select number of options' }
            ],
            initialValue: 2 // Set default value
          })(
            <Select>
              {[2, 3, 4].map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {status && status === generationStatus.error && (
          <Alert
            message="Error"
            description="Failed to generate maze. Please try again."
            type="error"
            closable
            showIcon
            icon={<CloseCircleOutlined />}
          />
        )}

        <br />

        <Form.Item>
          <Button
            htmlType="submit"
            shape="round"
            size="large"
            type="primary"
            onClick={handleOk}
            loading={isUploading || isGenerating}
          >
            {isUploading
              ? 'Uploading...'
              : isGenerating
              ? 'Generating...'
              : 'Generate MetaMaze'}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  const GenerationStatusView = () => {
    const { in_progress, completed, error } = generationStatus

    // Customize loading icon for Spin
    const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    if (status === in_progress) {
      return (
        <Result
          icon={<Spin indicator={loadingIcon} />}
          title="Generating MetaMaze..."
          subTitle="Your metamaze is currently being generated. You will be notified via email once the process is complete."
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push('/')}
            >
              Go To Training
            </Button>
          }
        />
      )
    }

    if (status === completed) {
      return (
        <Result
          status="success"
          title="MetaMaze Generated Successfully!"
          subTitle="You can now proceed with the metamaze."
          icon={<CheckCircleOutlined />}
        />
      )
    }

    return null
  }

  return (
    <Modal
      visible
      centered
      footer={null}
      closable={false}
      className="ai-maze-form-modal"
      width={800}
    >
      <div className="ai-maze-form-modal__header">
        <p>AI Metamaze Generation</p>
        <Icon type="close" onClick={handleModalClose} />
      </div>

      {status && status !== generationStatus.error
        ? GenerationStatusView()
        : GenerationFormView()}
    </Modal>
  )
}

// Wrap AiMazeGeneration component with Form.create() to access form methods via props
const WrappedAiMazeGeneration = Form.create({ name: 'ai_maze_generation' })(
  AiMazeGeneration
)

export default WrappedAiMazeGeneration
