import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { Unity, useUnityContext } from 'react-unity-webgl-latest';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Modal, Button, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { config } from '../../../../constants'
//import 'jspdf-autotable';
import './UnityView.scss';

function UnityView(props) {
  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    isLoaded,
    unload,
    loadingProgression,
    error: unityError
  } = useUnityContext({
    loaderUrl: '/uBuild/Build.loader.js',
    dataUrl: '/uBuild/Build.data',
    frameworkUrl: '/uBuild/Build.framework.js',
    codeUrl: '/uBuild/Build.wasm',
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const [error, setError] = useState(null);

  const contentRef = useRef();

  const fetchMazeSummary = async () => {
    try {
      const result = await axios.post(config.summarizeAPI, {
        program_id: props.mazeID,
        play_id: props.playID,
      });
      setResponse(result.data);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const sendMessageToUnity = (gameObject, method, parameter) => {
    try {
      sendMessage(gameObject, method, parameter);
    } catch (error) {
      console.error(`Error sending message to Unity: ${error}`);
      setError(`Error sending message to Unity: ${error}`);
    }
  };

  const handleSummary = useCallback((status) => {
    setLoading(true);
    fetchMazeSummary();
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    addEventListener('ShowSummary', handleSummary);
    return () => {
      removeEventListener('ShowSummary', handleSummary);
      unload()
        .then(() => {
          console.log("Unity unloaded successfully.");
        })
        .catch((error) => {
          console.error("Failed to unload Unity:", error);
        });
    };
  }, [addEventListener, removeEventListener, handleSummary, unload]);

  const handleOk = () => {
    if (response) {
      setResponse(null);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    if (response) {
      setResponse(null);
    }
    setIsModalVisible(false);
  };

  useEffect(() => {
    console.log("LOG GENDER : ", props)
    if (props.activateTTS) {
      console.log('props.activateTTS: ', props.activateTTS);
      sendMessageToUnity('_ManageJSON', 'LoadUnity', '');
    }
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveURL', props.baseURL);
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveToken', props.token);
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveUserId', props.mazeID);

    const assetBundleUrl =
      props.avatarGender === 'male'
        ? 'https://mj-scorm-bucket.s3-accelerate.amazonaws.com/urban_man_v6_unity2022'
        : 'https://mj-scorm-bucket.s3-accelerate.amazonaws.com/femaleofficecharacter_v6_unity2022';
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveAssetBundle', assetBundleUrl);
    // sendMessageToUnity('_GetTokenAndUserID', 'ReceiveGoogleTextToVoiceName', props.avatarVoice);
    // sendMessageToUnity('_GetTokenAndUserID', 'ReceiveGener', props.avatarGender);

    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveVoiceId', props.avatarVoice);
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveGender', props.avatarGender);
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveAccent', (props.avatarVoiceType === "traditional" ? "" : props.avatarAccent));
    // sendMessageToUnity('_GetTokenAndUserID', 'ReceiveAge', "middle-aged");    
    sendMessageToUnity('_GetTokenAndUserID', 'RecieveVoiceType', props.avatarVoiceType);
  }, [props]);

  useEffect(() => {
    if (isLoaded) {
      props.isBuildLoadedFunc(unityProvider);
    }

    const progressionPercentage = loadingProgression * 100;
    props.isPercentageLoader(progressionPercentage);

    if (unityError) {
      console.error('Unity error: ', unityError);
      setError(unityError);
    }
  }, [isLoaded, unityError, loadingProgression]);

  const handleDownloadPdf = () => {
    const content = contentRef.current;

    html2canvas(content, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("maze_summary.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <>
      {error && (
        <div style={{ color: 'red', textAlign: 'center' }}>
          An error occurred: {error}
        </div>
      )}

      {loadingProgression < 1 && (
        <p>Loading Unity... {Math.round(loadingProgression * 100)}%</p>
      )}

      <Unity
        unityProvider={unityProvider}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
      <Modal
        title="Maze Summary"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="download" type="primary" onClick={handleDownloadPdf} disabled={loading}>
            <DownloadOutlined /> Download Summary
          </Button>,
          <Button key="ok" type="default" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        {(loading &&
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Spin size="large" tip="Loading Maze Summary..." />
          </div>
        )}
        {(response &&
          <div ref={contentRef} className="container-unity">
            <div className="section-unity overview">
              <h2>Overview</h2>
              <p>
                {response.overview}
              </p>
            </div>

            <div className="section-unity step_wise_analysis">
              <h2>Step-Wise Analysis</h2>

              {response.step_wise_goal_title.map((title, index) => (
                <div className="step-analysis" key={index}>
                  <h3>Step {index + 1}: {title}</h3>
                  <p>{response.step_wise_analysis[index]}</p>
                </div>
              ))}
            </div>

            <div className="section-unity summary">
              <h2>Summary</h2>
              <p>
                {response.summary}
              </p>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default UnityView;
