import React, {  } from 'react'
import './styles.scss'
import { Col, Form, Select, TreeSelect } from 'antd'
import _ from 'lodash'
import { broadcastOptions } from '../../../../../constants'
const { TreeNode } = TreeSelect

const TopSection = props => {
  const {
    t,
    form: { getFieldDecorator },
    user: {
      info: { email }
    },
    current,
    emails,
    onFieldChange,
    encoderType,
    categories
  } = props

  return (
    <div className="event__mp__top">
      <div className="row">
        <Col md={6}>
          <Form.Item
            label={t('labels:broadcast_label')}
            colon={false}
            required={false}
          >
            {getFieldDecorator('encoder_type', {
              initialValue: encoderType
            })(
              <Select
                optionFilterProp="children"
                showSearch
                size="large"
                disabled
                filterOption={(input, option) =>
                  !_.isArray(option.props.children) &&
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.keys(broadcastOptions).map(item => (
                  <Select.Option key={item} value={item}>
                    {t(`broadcastOptions:${item}`)}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </div>
      <div className="row">
        <Col md={11}>
          <Form.Item label={t('labels:host')} colon={false} required={false}>
            {getFieldDecorator('host', {
              initialValue:
                current.host && current.host.email
                  ? current.host.email
                  : email
                  ? email
                  : emails[0]
            })(<Select disabled size="large" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={t('labels:category')}
            colon={false}
            required={false}
          >
            {getFieldDecorator('category', {
              rules: [
                {
                  required: true,
                  message: t('v4:select_category')
                }
              ],
              initialValue: current.marketplace.category_id
            })(
              <TreeSelect
                size="large"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                onChange={val => onFieldChange(val)}
              >
                {categories &&
                  categories.map(category => (
                    <TreeNode
                      value={category.id}
                      title={category.name}
                      key={category.id}
                    >
                      {category.children &&
                        category.children.map(option => (
                          <TreeNode
                            value={option.id}
                            title={option.name}
                            key={option.id}
                          />
                        ))}
                    </TreeNode>
                  ))}
              </TreeSelect>
            )}
          </Form.Item>
        </Col>
      </div>
    </div>
  )
}

export default TopSection
