/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-16 17:09:59
 * @LastEditors: Please set LastEditors
 */
import moment from 'moment'

import {
  WORKSHOP_COURSES_FETCHING,
  WORKSHOP_COURSES_SUCCESS,
  WORKSHOP_COURSES_HAS_MORE,
  WORKSHOP_EVENTS_FETCHING,
  WORKSHOP_EVENTS_SUCCESS,
  WORKSHOP_EVENTS_HAS_MORE,
  WORKSHOP_PODCAST_FETCHING,
  WORKSHOP_PODCAST_SUCCESS,
  WORKSHOP_PODCAST_HAS_MORE,
  WORKSHOP_ASSESSMENTS_SUCCESS,
  WORKSHOP_ASSESSMENTS_FETCHING,
  WORKSHOP_ASSESSMENTS_HAS_MORE,
  TRAINING_COURSES_FETCHING,
  TRAINING_COURSES_SUCCESS,
  TRAINING_COURSES_HAS_MORE,
  TRAINING_EVENTS_FETCHING,
  TRAINING_EVENTS_SUCCESS,
  TRAINING_EVENTS_HAS_MORE,
  ENROLLED_COURSES_SUCCESS,
  ENROLLED_COURSES_FETCHING,
  PAST_EVENTS_FETCHING,
  PAST_EVENTS_SUCCESS,
  NOW_LIVE_EVENTS_FETCHING,
  NOW_LIVE_EVENTS_SUCCESS,
  WEBINAR_EVENTS_FETCHING,
  WEBINAR_EVENTS_SUCCESS,
  PEER_TO_PEER_FETCHING,
  PEER_TO_PEER_SUCCESS,
  CONFERENCE_FETCHING,
  CONFERENCE_SUCCESS,
  WORKSHOP_SKILLJOURNEYS_FETCHING,
  WORKSHOP_SKILLJOURNEYS_SUCCESS,
  WORKSHOP_SKILLJOURNEYS_HAS_MORE,
  WORKSHOP_MAZES_FETCHING,
  WORKSHOP_MAZES_SUCCESS,
  WORKSHOP_MAZES_HAS_MORE,
  WORKSHOP_CHANNELS_FETCHING,
  WORKSHOP_CHANNELS_SUCCESS,
  WORKSHOP_CHANNELS_HAS_MORE
} from '../types'

import api from '../../services/api'

const checkHasMore = arr => arr && arr.length && arr.length > 0

export const getWorkshopData = (params, isOrderChanged, workshopType = ['all']) => (
  dispatch,
  getState
) => {
  const {
    courses: {
      workshopCourses: {
        fetch: coursesFetch,
        list: coursesList,
        hasMore: courseHasMore
      }
    },
    events: {
      workshopEvents: {
        fetch: eventsFetch,
        list: eventsList,
        hasMore: eventsHasMore
      },
      workshopPodcasts: {
        fetch: podcastsFetch,
        list: podcastsList,
        hasMore: podcastsHasMore
      }
    },
    skillJourney: {
      workshopSkillJourneys: {
        fetch: skillJourneysFetch,
        list: skillJourneysList,
        hasMore: skillJourneysHasMore
      }
    },
    maze: {
      workshopMazes: {
        fetch: mazesFetch,
        list: mazesList,
        hasMore: mazesHasMore
      }
    },
    channel: {
      workshopChannels: {
        fetch: channelsFetch,
        list: channelsList,
        hasMore: channelsHasMore
      }
    },
    user: {
      info: { active_organization_id: activeOrgId, organization }
    }
  } = getState()

  // Define which types to fetch based on workshopType
  const typesToFetch = {
    course: workshopType.includes('all') || workshopType.includes('course'),
    live_event: workshopType.includes('all') || workshopType.includes('live_event'),
    podcast: workshopType.includes('all') || workshopType.includes('podcast'),
    skill_journey: workshopType.includes('all') || workshopType.includes('skill_journey'),
    maze: workshopType.includes('all') || workshopType.includes('maze'),
    channel: workshopType.includes('all') || workshopType.includes('channel')
  }

  // Check if any fetches are in progress for selected types
  const isAnyFetchInProgress = Object.entries(typesToFetch).some(([type, shouldFetch]) => {
    if (!shouldFetch) return false
    switch (type) {
      case 'course': return coursesFetch
      case 'live_event': return eventsFetch
      case 'podcast': return podcastsFetch
      case 'skill_journey': return skillJourneysFetch
      case 'maze': return mazesFetch
      case 'channel': return channelsFetch
      default: return false
    }
  })

  if (isAnyFetchInProgress) return

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  // Set fetching states and clear data if order changed
  if (typesToFetch.course) {
    d(WORKSHOP_COURSES_FETCHING, true)
    if (isOrderChanged) d(WORKSHOP_COURSES_SUCCESS, [])
  }
  if (typesToFetch.live_event) {
    d(WORKSHOP_EVENTS_FETCHING, true)
    if (isOrderChanged) d(WORKSHOP_EVENTS_SUCCESS, [])
  }
  if (typesToFetch.podcast) {
    d(WORKSHOP_PODCAST_FETCHING, true)
    if (isOrderChanged) d(WORKSHOP_PODCAST_SUCCESS, [])
  }
  if (typesToFetch.skill_journey) {
    d(WORKSHOP_SKILLJOURNEYS_FETCHING, true)
    if (isOrderChanged) d(WORKSHOP_SKILLJOURNEYS_SUCCESS, [])
  }
  if (typesToFetch.maze) {
    d(WORKSHOP_MAZES_FETCHING, true)
    if (isOrderChanged) d(WORKSHOP_MAZES_SUCCESS, [])
  }
  if (typesToFetch.channel) {
    d(WORKSHOP_CHANNELS_FETCHING, true)
    if (isOrderChanged) d(WORKSHOP_CHANNELS_SUCCESS, [])
  }

  // Prepare API calls based on selected types
  const apiCalls = []
  const defaultResponse = Promise.resolve({ data: [] })

  if (typesToFetch.course) {
    const coursesParams = {
      ...params,
      mode: 'workshop',
      offset: isOrderChanged ? 0 : coursesList.length
    }
    apiCalls.push(courseHasMore || isOrderChanged
      ? api.courses.getCoursesList(coursesParams)
      : defaultResponse)
  } else {
    apiCalls.push(defaultResponse)
  }

  if (typesToFetch.live_event) {
    const eventsParams = {
      ...params,
      mode: 'workshop',
      offset: isOrderChanged ? 0 : eventsList.length
    }
    apiCalls.push(eventsHasMore || isOrderChanged
      ? api.events.get(eventsParams)
      : defaultResponse)
  } else {
    apiCalls.push(defaultResponse)
  }

  if (typesToFetch.podcast) {
    const podcastsParams = {
      ...params,
      mode: 'workshop',
      schedule_state: 'all',
      event_type: 'discussion_room',
      offset: isOrderChanged ? 0 : podcastsList.length
    }
    apiCalls.push(podcastsHasMore || isOrderChanged
      ? api.events.get(podcastsParams)
      : defaultResponse)
  } else {
    apiCalls.push(defaultResponse)
  }

  if (typesToFetch.skill_journey) {
    const skillJourneysParams = {
      ...params,
      mode: 'workshop',
      offset: isOrderChanged ? 0 : skillJourneysList.length
    }
    apiCalls.push(skillJourneysHasMore || isOrderChanged
      ? api.skillJourney.listSkillJourneys(skillJourneysParams)
      : defaultResponse)
  } else {
    apiCalls.push(defaultResponse)
  }

  if (typesToFetch.maze) {
    const mazesParams = {
      ...params,
      mode: 'workshop',
      offset: isOrderChanged ? 0 : mazesList.length
    }
    apiCalls.push((mazesHasMore || isOrderChanged) &&
      (activeOrgId === null ||
        activeOrgId === 1 ||
        (organization && organization.enable_maze))
      ? api.maze.listMazes(mazesParams)
      : Promise.resolve({ data: { data: [] } }))
  } else {
    apiCalls.push(Promise.resolve({ data: { data: [] } }))
  }

  if (typesToFetch.channel) {
    const channelsParams = {
      ...params,
      mode: 'workshop',
      offset: isOrderChanged ? 0 : channelsList.length
    }
    apiCalls.push(channelsHasMore || isOrderChanged
      ? api.channel.listChannels(channelsParams)
      : defaultResponse)
  } else {
    apiCalls.push(defaultResponse)
  }

  return Promise.allSettled(apiCalls).then(resolvedResponse => {
    const [
      courseResponse,
      eventsResponse,
      podcastsResponse,
      skillJourneysResponse,
      mazesResponse,
      channelsResponse
    ] = resolvedResponse

    // Process responses and update state only for selected types
    if (typesToFetch.course) {
      const coursesData = courseResponse.value.data || []
      d(WORKSHOP_COURSES_FETCHING, false)
      d(WORKSHOP_COURSES_HAS_MORE, checkHasMore(coursesData))
      const newCourses = coursesData.map(i => ({ ...i, type: 'Course' }))
      d(WORKSHOP_COURSES_SUCCESS, (isOrderChanged ? [] : coursesList).concat(newCourses))
    }

    if (typesToFetch.live_event) {
      const eventsData = eventsResponse.value.data || []
      d(WORKSHOP_EVENTS_FETCHING, false)
      d(WORKSHOP_EVENTS_HAS_MORE, checkHasMore(eventsData))
      const newEvents = eventsData.map(i => ({ ...i, type: 'Live Event' }))
      d(WORKSHOP_EVENTS_SUCCESS, (isOrderChanged ? [] : eventsList).concat(newEvents))
    }

    if (typesToFetch.podcast) {
      const podcastsData = podcastsResponse.value.data || []
      d(WORKSHOP_PODCAST_FETCHING, false)
      d(WORKSHOP_PODCAST_HAS_MORE, checkHasMore(podcastsData))
      const newPodcasts = podcastsData.map(i => ({ ...i, type: 'Podcast' }))
      d(WORKSHOP_PODCAST_SUCCESS, (isOrderChanged ? [] : podcastsList).concat(newPodcasts))
    }

    if (typesToFetch.skill_journey) {
      const skillJourneysData = skillJourneysResponse.value.data || []
      d(WORKSHOP_SKILLJOURNEYS_FETCHING, false)
      d(WORKSHOP_SKILLJOURNEYS_HAS_MORE, checkHasMore(skillJourneysData))
      const newSkillJourneys = skillJourneysData.map(i => ({ ...i, type: 'Skill Journey' }))
      d(WORKSHOP_SKILLJOURNEYS_SUCCESS, (isOrderChanged ? [] : skillJourneysList).concat(newSkillJourneys))
    }

    if (typesToFetch.maze) {
      const mazesData = (mazesResponse.status === 'fulfilled' && mazesResponse.value.data && mazesResponse.value.data.data) || []
      d(WORKSHOP_MAZES_FETCHING, false)
      d(WORKSHOP_MAZES_HAS_MORE, checkHasMore(mazesData))
      const newMazes = mazesData.map(i => ({ ...i, type: 'Maze' }))
      d(WORKSHOP_MAZES_SUCCESS, (isOrderChanged ? [] : mazesList).concat(newMazes))
    }

    if (typesToFetch.channel) {
      const channelsData = channelsResponse.value.data || []
      d(WORKSHOP_CHANNELS_FETCHING, false)
      d(WORKSHOP_CHANNELS_HAS_MORE, checkHasMore(channelsData))
      const newChannels = channelsData.map(i => ({ ...i, type: 'Channel' }))
      d(WORKSHOP_CHANNELS_SUCCESS, (isOrderChanged ? [] : channelsList).concat(newChannels))
    }
  })
}

export const getTrainingData = (params, isOrderChanged) => (
  dispatch,
  getState
) => {
  const {
    courses: {
      trainingCourses: {
        fetch: coursesFetch,
        list: coursesList
        // hasMore: courseHasMore
      }
    },
    events: {
      trainingEvents: {
        fetch: eventsFetch,
        list: eventsList
        // hasMore: eventsHasMore
      }
    }
  } = getState()

  if (coursesFetch || eventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const coursesParams = {
    ...params,
    mode: 'training',
    offset: isOrderChanged ? 0 : coursesList.length
  }
  const eventsParams = {
    ...params,
    mode: 'training',
    offset: isOrderChanged ? 0 : eventsList.length
  }

  d(TRAINING_COURSES_FETCHING, true)
  d(TRAINING_EVENTS_FETCHING, true)

  if (isOrderChanged) {
    d(TRAINING_COURSES_SUCCESS, [])
    d(TRAINING_EVENTS_SUCCESS, [])
  }

  return Promise.all([
    // courseHasMore
    //   ? api.courses.getCoursesList(coursesParams)
    //   : Promise.resolve({ data: [] }),
    // eventsHasMore
    //   ? api.events.get(eventsParams)
    //   : Promise.resolve({ data: [] })]
    api.courses.getCoursesList(coursesParams),
    api.events.get(eventsParams)
  ])
    .then(([{ data: coursesData }, { data: eventsData }]) => {
      d(TRAINING_COURSES_FETCHING, false)
      d(TRAINING_EVENTS_FETCHING, false)

      d(TRAINING_COURSES_HAS_MORE, !!coursesData.length)
      d(TRAINING_EVENTS_HAS_MORE, !!eventsData.length)

      let newEvents = []
      let newCourses = []
      if (eventsData.length > 0) {
        newEvents = eventsData.map(i => ({
          ...i,
          start_date: i.start_date_time,
          type: 'Live Event'
        }))
      }
      if (coursesData.length > 0) {
        newCourses = coursesData.map(i => ({
          ...i,
          type: 'Course'
        }))
      }

      d(
        TRAINING_COURSES_SUCCESS,
        (isOrderChanged ? [] : coursesList).concat(newCourses)
      )
      d(
        TRAINING_EVENTS_SUCCESS,
        (isOrderChanged ? [] : eventsList).concat(newEvents)
      )
    })
    .catch(e => {
      console.log('error', e)
    })
}

export const getEnrolledData = params => (dispatch, getState) => {
  const {
    courses: {
      enrolledCourses: { fetch: coursesFetch }
    },
    events: {
      enrolledEvents: { fetch: eventsFetch }
    }
  } = getState()

  if (coursesFetch || eventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const coursesParams = {
    ...params,
    mode: 'enrollment'
  }
  // const eventsParams = {
  //   ...params,
  //   mode: 'enrollment'
  // }

  d(ENROLLED_COURSES_FETCHING, true)

  return Promise.all([
    api.courses.getCoursesList(coursesParams)
    // api.events.get(eventsParams)
  ]).then(([{ data: coursesData }]) => {
    if (!coursesData) {
      return null
    }

    d(ENROLLED_COURSES_FETCHING, false)
    // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
    // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

    const newCourses =
      coursesData.length > 0
        ? coursesData.map(i => ({
          ...i,
          type: 'Course'
        }))
        : []

    const union = newCourses
      .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
      .slice(0, params.limit)

    const courses = union.filter(i => i.type === 'Course')

    d(ENROLLED_COURSES_SUCCESS, courses)
  })
}

export const getPastEventData = params => (dispatch, getState) => {
  const {
    events: {
      pastEvents: { fetch: pastEventsFetch }
    }
  } = getState()

  if (pastEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'past'
  }

  d(PAST_EVENTS_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(PAST_EVENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

      const pastEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
            i.type = 'Live Event'
            return i
          })
          : []

      d(PAST_EVENTS_SUCCESS, pastEvents)
    }
  )
}

export const getNowLiveEventsData = params => (dispatch, getState) => {
  const {
    events: {
      nowLiveEvents: { fetch: nowLiveEventsFetch }
    }
  } = getState()

  if (nowLiveEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'live'
  }

  d(NOW_LIVE_EVENTS_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(NOW_LIVE_EVENTS_FETCHING, false)

      // d(WORKSHOP_COURSES_HAS_MORE, !!coursesData.length)
      // d(WORKSHOP_EVENTS_HAS_MORE, !!eventsData.length)

      const nowLiveEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
            i.type = 'Live Event'
            return i
          })
          : []

      d(NOW_LIVE_EVENTS_SUCCESS, nowLiveEvents)
    }
  )
}

export const getWebinarEventData = params => (dispatch, getState) => {
  const {
    events: {
      webinarEvents: { fetch: webinarEventsFetch }
    }
  } = getState()

  if (webinarEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'webinar'
  }

  d(WEBINAR_EVENTS_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(WEBINAR_EVENTS_FETCHING, false)

      const webinarEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
            i.type = 'Webinar Event'
            return i
          })
          : []

      d(WEBINAR_EVENTS_SUCCESS, webinarEvents)
    }
  )
}

export const getPeerToPeerData = params => (dispatch, getState) => {
  const {
    events: {
      peerToPeer: { fetch: peerToPeerFetch }
    }
  } = getState()

  if (peerToPeerFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'peer2peer'
  }

  d(PEER_TO_PEER_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(PEER_TO_PEER_FETCHING, false)

      const peerToPeer =
        eventsData.length > 0
          ? eventsData.map(i => {
            i.type = 'Coaching Event'
            return i
          })
          : []

      d(PEER_TO_PEER_SUCCESS, peerToPeer)
    }
  )
}

export const getConferenceData = params => (dispatch, getState) => {
  const {
    events: {
      conferenceEvents: { fetch: conferenceEventsFetch }
    }
  } = getState()

  if (conferenceEventsFetch) {
    return
  }

  const d = (type, payload) =>
    dispatch({
      type,
      payload
    })

  const eventsParams = {
    ...params,
    mode: 'conference'
  }

  d(CONFERENCE_FETCHING, true)

  return Promise.all([api.events.get(eventsParams)]).then(
    ([{ data: eventsData }]) => {
      if (!eventsData) {
        return null
      }

      d(CONFERENCE_FETCHING, false)

      const conferenceEvents =
        eventsData.length > 0
          ? eventsData.map(i => {
            i.type = 'Conference Event'
            return i
          })
          : []

      d(CONFERENCE_SUCCESS, conferenceEvents)
    }
  )
}
