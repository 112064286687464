import React, { Component } from 'react'
// import { Modal, Button } from 'antd'
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import './ImageEditor.scss'
// import { general } from '../../constants'

const icona = require('tui-image-editor/dist/svg/icon-a.svg')
const iconb = require('tui-image-editor/dist/svg/icon-b.svg')
const iconc = require('tui-image-editor/dist/svg/icon-c.svg')
const icond = require('tui-image-editor/dist/svg/icon-d.svg')

const myTheme = {
  'menu.backgroundColor': 'white',
  'common.backgroundColor': '#151515',
  'downloadButton.backgroundColor': 'white',
  'downloadButton.borderColor': 'white',
  'downloadButton.color': 'black',
  'menu.normalIcon.path': icond,
  'menu.activeIcon.path': iconb,
  'menu.disabledIcon.path': icona,
  'menu.hoverIcon.path': iconc
}

class Editor extends Component {
  // imageEditor = createRef()
  saveImage = () => {
    const imageEditorInst = this.imageEditor.current.imageEditorInst
    const data = imageEditorInst.toDataURL()
    // var bs = atob(data.replace('data:image/png;base64,', ''))
    var bs = Buffer.from(data, 'base64')
    var buffer = new ArrayBuffer(bs.length)
    var ba = new Uint8Array(buffer)
    for (var i = 0; i < bs.length; i++) {
      ba[i] = bs.charCodeAt(i)
    }
    var file = new Blob([ba], { type: 'image/png' })
    // this.props.handleSaveChanges(data)

    console.log('image data: ', data)
    console.log('image file: ', file)
    // if (data) {
    //     const mimeType = data.split(";")[0];
    //     const extension = data.split(";")[0].split("/")[1];
    //     download(data, `image.${extension}`, mimeType);
    // }
  }

  render() {
    const {
      library: { link: imageUrl }
    } = this.props
    const { disableOptions } = this.props
    let urlPath = ''
    if (imageUrl.includes('token')) {
      urlPath = imageUrl + '+&t=' + new Date().getTime()
    } else {
      urlPath = imageUrl + '?t=' + new Date().getTime()
    }
    return (
      <div>
        <ImageEditor
          includeUI={{
            loadImage: {
              // temportary solution for CORS
              path: urlPath,
              name: 'editorimage'
            },
            theme: myTheme,
            menu: disableOptions ? ['crop'] : ['text', 'crop'],
            initMenu: disableOptions ? 'crop' : '',
            uiSize: {
              height: `calc(100vh - 160px)`
            },
            menuBarPosition: 'bottom'
          }}
          cssMaxHeight={window.innerHeight}
          cssMaxWidth={window.innerWidth}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70
          }}
          usageStatistics={false}
          ref={this.props.innerRef}
        />
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Editor innerRef={ref} {...props} />
))
