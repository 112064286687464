import React, { Component, Fragment } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { CustomTabs, ReportTable, BackButton } from '../../styled.js'
import { isSafari } from 'react-device-detect'
import { userImage } from '../../images'
import ReportHeader from '../../components/ReportHeader.jsx'
import { Spinner } from '../../../../components'
import { withTranslation } from 'react-i18next'
import TypeSelect from '../../components/TypeSelect.jsx'
import { report } from '../../../../constants'
import { Tabs, Icon, Row, Card, Col, Divider } from 'antd'
import { generateCSV } from '../../../../utils'
import moment from 'moment/moment'
import '../../Report/style.css'
import './styles.scss'
import { api } from '../../../../services'

const { TabPane } = Tabs

const columnsAttendee = t => [
  {
    title: t('reports:assesse_trainee'),
    dataIndex: 'avatar',
    width: 150,
    render: avatar => {
      console.log('avatar', avatar)
      return (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <img
            style={{
              objectFit: 'contain',
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              border: '2px solid #c4c4c4'
            }}
            src={avatar && avatar.link ? avatar.link : userImage}
            alt="userimage"
          />
        </div>
      )
    }
  },
  {
    dataIndex: 'username',
    textWrap: 'word-break',
    width: 200
  },
  {
    title: t('reports:score'),
    dataIndex: 'average_score',
    render: data => {
      let result = data ? data && data.toFixed(2) : '0'
      return <p>{result}</p>
    }
  },
  { title: t('v4:pass_score'), dataIndex: 'pass_score' },
  {
    title: t('reports:status'),
    dataIndex: 'pass_status',
    render: status => {
      let color = status === true ? '#20AA26' : '#BC0000'
      let result =
        status === true ? 'Passed' : status === false ? 'Failed' : 'Unknown'

      return <p style={{ color: color, marginBottom: 0 }}>{result}</p>
    }
  }
]

const columns = t => [
  {
    title: t('reports:assessors_trainers'),
    dataIndex: 'avatar',
    width: 150,
    render: avatar => {
      console.log('avatar', avatar)
      return (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <img
            style={{
              objectFit: 'contain',
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              border: '2px solid #c4c4c4'
            }}
            src={avatar && avatar.link ? avatar.link : userImage}
            alt="userimage"
          />
        </div>
      )
    }
  },

  {
    dataIndex: 'username',
    textWrap: 'word-break',
    width: 200
  },
  {
    title: t('v4:avg_score'),
    dataIndex: 'averageScore',
    render: data => {
      let result = data ? data && data.toFixed(2) : '0'
      return <p>{result}</p>
    }
  },

  { title: t('v4:detail') }
]

let expandSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F0F2F5" />
    <path d="M4 6L8.5 11L13 6" stroke="#246CF6" />
  </svg>
)

class Preview extends Component {
  state = {
    searchType: 'search',
    groups: [],
    loading: true,
    expandedRowKeys: 0,
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    attendeeData: [],
    overallData: [],
    hostsData: [],
    questionsData: [],
    answersData: [],
    visible: false,
    expendIdNo: 0
  }

  getTimeDuration = time => {
    const t = moment.duration(time).asMinutes()
    return `${t.toFixed(2)} minutes`
  }

  renderLiveAssessmentsTable = (record, answersData, questionsData) => {
    const { t } = this.props
    let answerItem
    answersData &&
      answersData.map((item, index) =>
        record.id === item.user_id ? (answerItem = item) : null
      )
    let answersLength = answerItem && answerItem.score_info.length

    let hostData = questionsData.map((item, i) =>
      Object.assign({}, item, answerItem && answerItem.score_info[i])
    )

    let totalScore =
      answerItem &&
      answerItem.score_info.reduce((total, item) => total + item.score, 0)

    const averageScore =
      totalScore && answersLength && totalScore / answersLength

    return (
      <table className="lessons-table">
        <thead>
          <tr>
            <th>{t('labels:question')}</th>
            <th>{t('reports:score')}</th>
          </tr>
        </thead>
        <tbody>
          {hostData.map((item, index) => (
            <tr key={index} style={{ fontWeight: 'normal' }}>
              <td>{item.question}</td>
              <td style={{ textAlign: 'center' }}>
                {item.score ? item.score : '-'}
              </td>
            </tr>
          ))}
          <tr>
            <td>{t('v2:total')}</td>
            <td style={{ textAlign: 'center' }}>
              {totalScore ? totalScore : '-'}
            </td>
          </tr>
          <tr>
            <td>{t('v2:average')}</td>
            <td style={{ textAlign: 'center' }}>
              {averageScore ? averageScore.toFixed(2) : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  onExpand = (expanded, record) => {
    if (this.state.expendIdNo===record.id) {
      this.setState({
        expendIdNo: 0
      })
    } else {
      this.setState({
        expendIdNo: record.id
      })
    }
    var keys = []
    if (expanded) {
      keys.push(record.id)
    }
    this.setState({ expandedRowKeys: keys })
  }

  download = () => {
    const {
      reports: {
        liveAssessments: {
          data,
          data: { attendee = [] }
        }
      }
    } = this.props

    const { hostsData: hosts = [] } = this.state

    let dataCSV = []
    let header = []

    data &&
      attendee &&
      dataCSV.push({
        title: data.title,
        attendee_username: attendee && attendee.username,
        host_username: '',
        host_score: '',
        created_date: moment(data.created_at).format('YYYY-MM-DD'),
        start_date: moment(data.start_date_time).format('YYYY-MM-DD'),
        pass_score: data.pass_score,
        score: data.average_score.toFixed(2),
        pass_status:
          data.pass_status === true
            ? 'Passed'
            : data.pass_status === false
            ? 'Failed'
            : 'Unknown'
      })

    hosts &&
      hosts.forEach(item => {
        dataCSV.push({
          attendee_username: '',
          title: '',
          host_username: item.username,
          host_score: item.averageScore
        })
      })

    header = [
      'Title',
      'Assesse/Trainee',
      'Hosts',
      'Average Score Given By Host',
      'Created Date',
      'Start Date',
      'Pass Score',
      'Score',
      'Pass Status'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'live-assessments-report.csv')
      document.body.appendChild(ele)
      ele.click()
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'live-assessments-report.csv')
      ele.click()
    }
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    this.setState({
      page: page
    })
  }

  fetchGroups = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    api.organizations.get().then(res => {
      const activeOrg = res.data.rows.filter(
        item => item.id === active_organization_id
      )
      if (activeOrg && activeOrg[0]) {
        this.setState({
          groups: activeOrg[0].groups
        })
      }
    })
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props
    await this.props.getLiveAssessmentsReport(id)

    const {
      reports: {
        liveAssessments: { data }
      }
    } = this.props
    const {
      reports: {
        liveAssessments: {
          data: {
            host,
            hosts,
            attendee,
            live_assessment_scores = [],
            live_assessment_questions
          }
        }
      }
    } = this.props

    const hostData = [host, ...hosts]

    const hostWithScore = hostData.map(host => {
      const scoreOfHost = live_assessment_scores.filter(
        item => item.user_id === host.id
      )[0]

      const scoreArray = scoreOfHost && scoreOfHost.score_info

      const totalScore =
        scoreArray && scoreArray.reduce((acc, item) => acc + item.score, 0)

      const averageScore = totalScore / Number(scoreArray && scoreArray.length)

      return {
        ...host,
        averageScore
      }
    })

    this.setState({
      loading: false,
      answersData: live_assessment_scores,
      questionsData: live_assessment_questions,
      overallData: data && Object.entries(data),
      attendeeData: attendee,
      hostsData: hostWithScore
    })
    this.fetchGroups()
  }

  customExpandIcon = props => {
    return this.state.expendIdNo===props.record.id ? (
      <Icon type="up" />
    ) : (
      <Icon type="down" />
    )
  }

  callbackTabClicked = (key, event) => {
    if (key === '1') {
      this.props.history.push('/reports', 'user')
    } else if (key === '3') {
      this.props.history.push(`/reports`, 'marketplace')
    }
  }

  render() {
    const { loading, hostsData, answersData, questionsData } = this.state

    const {
      t,
      reports: {
        liveAssessments: {
          data: {
            title,
            hosts,
            attendee,
            created_at,
            cover,
            description,
            pass_score,
            pass_status,
            average_score
          },
          fetch
        }
      }
    } = this.props
    const dataList = hostsData
    const newData = [
      {
        ...attendee,
        title,
        description,
        created_at,
        pass_score,
        pass_status,
        average_score
      }
    ]

    return (
      <Fragment>
        {fetch || loading ? (
          <Spinner />
        ) : (
          <div className="content-warp">
            <div className="report-warp" style={{ direction: 'ltr' }}>
              <CustomTabs
                defaultActiveKey="0"
                onTabClick={this.callbackTabClicked}
                tabBarExtraContent={
                  <ReportHeader download={this.download} isDateActive />
                }
              >
                <TabPane tab={t('reports:workshop_report')} key="0">
                  <TypeSelect defaultValue="live_assessments" />
                  <div className="custom-table">
                    <Row>
                      <Col span={8}>
                        <Row>
                          <BackButton
                            onClick={() =>
                              this.props.history.push(
                                '/reports/live-assessments'
                              )
                            }
                            size="large"
                            style={{
                              marginTop: '20px',
                              marginLeft: '15px'
                            }}
                          >
                            <LeftOutlined />
                            {t('v4:back')}
                          </BackButton>
                        </Row>

                        <Card className={'custom-card'}>
                          <img
                            className="table-img"
                            src={cover && cover.link}
                            style={{ cursor: 'pointer' }}
                            alt=""
                          />
                          <p className="custom-description">{description}</p>
                          <Divider
                            type="horizontal "
                            dashed
                            style={{ borderColor: 'rgb(155 155 155)' }}
                          />
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeigth: 'bold', color: 'black' }}
                            >
                              {t('reports:created_date').toUpperCase()}
                            </Col>
                            <Col span={12} push={1}>
                              {moment(created_at).format('YYYY/MM/DD')}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:assessment_title').toUpperCase()}
                            </Col>
                            <Col span={12} push={1}>
                              {title}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:description').toUpperCase()}
                            </Col>
                            <Col span={12} push={1}>
                              {description || t('v2:n_data').toUpperCase()}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={16}>
                        <ReportTable
                          rowKey="id"
                          dataSource={newData}
                          pagination={false}
                          columns={columnsAttendee(t)}
                          width
                          style={{
                            width: '92%',
                            marginLeft: '35px',
                            marginTop: '1.5rem'
                          }}
                        ></ReportTable>
                      </Col>
                      <Col span={16}>
                        <ReportTable
                          rowKey="id"
                          dataSource={dataList}
                          expandedRowRender={record =>
                            this.renderLiveAssessmentsTable(
                              record,
                              answersData,
                              questionsData
                            )
                          }
                          onExpand={this.onExpand}
                          expandIcon={this.customExpandIcon}
                          expandedRowKeys={this.state.expandedRowKeys || []}
                          expandRowByClick
                          expandIconAsCell={false}
                          expandIconColumnIndex={3}
                          pagination={false}
                          columns={columns(t)}
                          width
                          style={{
                            width: '92%',
                            marginLeft: '35px'
                          }}
                          title={() => (
                            <>
                              <Row
                                style={{
                                  marginLeft: '10px',
                                  marginTop: '3px'
                                }}
                              >
                                <Col
                                  className="report-title"
                                  span={15}
                                  push={17}
                                >
                                  {t('v4:total')} {hosts.length + 1}{' '}
                                  {t(
                                    'reports:assessors_trainers'
                                  ).toUpperCase()}
                                </Col>
                              </Row>
                              <Divider style={{ margin: '0px' }} />
                            </>
                          )}
                        ></ReportTable>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab={t('reports:user_report')} key="1"></TabPane>
                <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
              </CustomTabs>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('report')(Preview)
