import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import { Spin, Form, Modal, Button, Input } from 'antd'
import className from 'classnames'
import Detail from './Detail'
import Preview from '../../../../Library/components/Preview'
import { PopupMedia } from '../../../../Library/internal'
import { modal_style } from '../../styled'
import { Row, Col } from '../../../../../styles'
import SwitchSettings from './SwitchSettings'
import Setting from './Setting'
import Poll from '../Poll'
import AssessmentForm from './AssessmentForm'
import { ImageSelect } from '../../../../../components/Form'

class CreateForm extends Component {
  render() {
    const routeId = this.props.match.params.id || null
    const spinning = routeId ? this.props.isFetching : false
    const {
      t,
      modalOpen,
      toSelectFile,
      current,
      selectedItem,
      App: {
        toggleMediaModal,
        handleInsertData,
        handleInsertFile,
        handleRemoveSelected,
        handleFileInsert,
        onDropPdf,
        toggleFileModal,
        addPoll,
        handleParam,
        deletePoll,
        addAnswer,
        deleteAnswer,
        handleQuestion,
        handleAnswer,
        handleForm,
        hidePreviewModal
      },
      form,
      form: { getFieldDecorator, getFieldValue },
      isSaving,
      library: { uploadProgress },
      encoderType,
      coverId,
      coverLink,
      coverSize,
      coverName
    } = this.props

    return (
      <Spin spinning={spinning}>
        <section className="section" style={{ padding: '2.5rem 0' }}>
          <Form>
            <Row>
              <Col md={6}>
                <Detail {...this.props} />
              </Col>
              <Col md={6}>
                <ImageSelect
                  form={form}
                  coverInput={
                    coverName
                      ? {
                          id: coverId,
                          link: coverLink,
                          file_size: coverSize,
                          filename: coverName
                        }
                      : null
                  }
                  onCoverChange={handleFileInsert}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item
                  label={t('labels:description')}
                  colon={false}
                  required={false}
                >
                  {getFieldDecorator('description', {
                    initialValue: current.description
                  })(
                    <Input.TextArea
                      rows={4}
                      name="description"
                      placeholder={t('placeholders:description')}
                      size="large"
                      maxLength={1000}
                      onChange={handleParam}
                      style={{ fontSize: '1.6rem', resize: 'none' }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <SwitchSettings {...this.props} />
            </Row>
            {getFieldValue('is_poll') && (
              <Poll
                data={current.poll}
                addQuestion={addPoll}
                deleteQuestion={deletePoll}
                addAnswer={addAnswer}
                deleteAnswer={deleteAnswer}
                handleQuestion={handleQuestion}
                handleAnswer={handleAnswer}
                savePoll={handleForm}
                isSaving={isSaving}
              />
            )}

            {encoderType === 'live_assessment' && (
              <AssessmentForm {...this.props} />
            )}

            <hr
              style={{
                color: '#ffffff',
                backgroundColor: '#ffffff',
                height: 0
              }}
            />
            <section className="section" style={{ padding: '2.5rem 0' }}>
              <div className="head head_nopadding">
                <div className="head__title">{t('publishsettings:title')}</div>
                {/* <div className="head__subtitle">{t('event_subtitle')}</div> */}
              </div>
            </section>
            <Setting {...this.props} />
          </Form>
          {modalOpen && (
            <Modal
              visible={modalOpen}
              onCancel={toggleMediaModal}
              footer={null}
              width={'80%'}
              style={modal_style}
            >
              <div className="wrap_modal_tabs">
                <PopupMedia
                  isPageBuilder
                  types={!toSelectFile ? 'images' : 'pdf'}
                  handleInsertData={
                    !toSelectFile ? handleInsertData : handleInsertFile
                  }
                  handleInsertFile={handleInsertFile}
                  selectedFiles={current.attachments}
                  handleRemoveSelected={handleRemoveSelected}
                />
              </div>
              {selectedItem && (
                <Modal
                  visible={selectedItem}
                  onCancel={hidePreviewModal}
                  footer={null}
                >
                  <Preview library={selectedItem} />
                  <div className="wrap_btn_actions">
                    <Button
                      type="danger"
                      size="large"
                      className="action_button"
                      onClick={hidePreviewModal}
                    >
                      {t('labels:cancel')}
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      className="action_button"
                      onClick={() => handleFileInsert(selectedItem)}
                    >
                      {t('labels:insert')}
                    </Button>
                  </div>
                </Modal>
              )}
            </Modal>
          )}
          {toSelectFile && (
            <Modal
              title={t('v4:select_file_attach')}
              visible={toSelectFile}
              onCancel={toggleFileModal}
              className="custom-ant-modal"
              destroyOnClose
              width={600}
              footer={null}
              // width={'40%'}
              // style={modal_style}
            >
              <Dropzone
                accept={'.pdf'}
                onDrop={onDropPdf}
                style={{}}
                disabled={uploadProgress.length > 0}
                multiple
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={className(
                      'upload-dropzone',
                      'upload-dropzone_event',
                      {
                        'upload-dropzone_active': isDragActive
                      }
                    )}
                  >
                    <div className="wrap_upload_buttons">
                      <Button
                        className="rounded"
                        size="large"
                        icon="filepdfoutlined"
                      >
                        {t('v4:upload_pdf')}
                      </Button>
                      <Button
                        className="rounded"
                        size="large"
                        icon="filepdfoutlined"
                        onClick={e => {
                          e.stopPropagation()
                          toggleMediaModal()
                        }}
                      >
                        {t('buttons:select_media_library')}
                      </Button>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Modal>
          )}
        </section>
      </Spin>
    )
  }
}

export default withTranslation('events')(CreateForm)
