import React, { useEffect, useState } from 'react'

import TrainingListContainer from '../components/TrainingListContainer'
import TrainingListActions from '../components/TrainingListActions'

import { Alert, Card as AntdCard, Col, Pagination, Row, message } from 'antd'
import { Container, Draggable } from 'react-smooth-dnd'

import { types } from '../types'
import { api } from '../../../../../services'
import { Card } from '../../../../../components'
import TrainingSequence from '../components/TrainningSquence'
import { useTranslation } from 'react-i18next'

function CourseList(props) {
  const [coursesData, setCourses] = useState([])
  const [pagination, setPagination] = useState({ page: 1, limit: 12, total: null, offset: 0 })
  const [loading, setLoading] = useState(true)
  const [priorityLoad, setPriorityLoad] = useState(false)
  const [error, setError] = useState(false)
  const [courseType, setCourseType] = useState('new')
  const [search, setSearch] = useState('')
  const [sequenceState, setSequenceState] = useState(false)
  const [seqList, setSeqList] = useState([])
  const [newSequence, setNewSequence] = useState([])
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchCourses();
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [search, courseType, pagination.page, pagination.offset]);
  useEffect(() => {
    if (sequenceState) {
      fetchPrioritiesCourses()
    }
  }, [sequenceState])
  const fetchCourses = () => {
    if (!loading) setLoading(true)
    api.courses
      .getCoursesList({
        mode: courseType,
        order: 'created_at',
        offset: pagination.offset,
        search: search,
        limit: pagination.limit
      })
      .then(res => {
        setCourses(res.data.data)
        setPagination({ ...pagination, total: res.data.pagination.total })
        setLoading(false)
        setError(false)
      })
      .catch(err => {
        setCourses([])
        setPagination({ ...pagination, total: null })
        setLoading(false)
        setError(true)
      })
  }
  const fetchPrioritiesCourses = () => {
    setPriorityLoad(true)
    api.courses
      .getCoursesList({
        mode: 'priorities',
        order: 'created_at',
        offset: pagination.offset,
        limit: 10
      })
      .then(({ data }) => {
        setSeqList(data.data)
        setPriorityLoad(false)
      })
      .catch(err => {
        setPriorityLoad(false)
      })
  }
  const handlePagination = pageNumber => {
    setPagination({ ...pagination, page: pageNumber, offset: pageNumber * pagination.limit - pagination.limit })
  }
  const handleTagSelect = tag => {
    setPagination({ ...pagination, page: 1, offset: 0 })
    setCourseType(tag)
  }

  const handleSearch = searchValue => {
    setPagination({ ...pagination, page: 1, offset: 0 })
    setSearch(searchValue)
  }

  const onCardClick = ({ id }) => props.history.push(`/courses/${id}`)

  // const currentPageNumber = useMemo(() => pageOffset / 10 + 1, [pageOffset])

  const handleSaveSequence = () => {
    if (newSequence.length > 0) {
      setPriorityLoad(true)
      let data = []
      newSequence.forEach((item, i) => {
        data.push({ course_id: item.id, priority: i })
      })
      api.courses
        .setSequence({ courses: data })
        .then(res => {
          message.success('Order sequence set successfully')
          setPriorityLoad(false)
          setSequenceState(false)
        })
        .catch(err => {
          message.error(err.message)
          setPriorityLoad(false)
          setSequenceState(false)
        })
    } else {
      message.warn('No Data: Sequence set failed!')
      setSequenceState(false)
    }
  }
  const { t } = useTranslation()
  return (
    <TrainingListContainer title={t('reports:courses')}>
      <TrainingListActions
        type={types.COURSE}
        loading={loading}
        onTagSelect={handleTagSelect}
        onListSearch={handleSearch}
        sequenceState={sequenceState}
        handleSaveSequence={handleSaveSequence}
        openSequenceState={val => {
          setSequenceState(val)
        }}
      />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={sequenceState ? 16 : 24}>
          {!loading && coursesData.length > 0 && (
            <div className="training-list-pagination">
              <h2>
                {search && (
                  <>
                    {t('v4:search_result')} <u>{search}</u> -
                  </>
                )}{' '}
                {coursesData.length} {t('v3:items')}
              </h2>
            </div>
          )}
          <div className="training-list-cards">
            {loading ? (
              Array.from(Array(pagination.limit).keys()).map(item => (
                <AntdCard style={{ width: 250 }} loading>
                  {t('v4:this_card')}
                </AntdCard>
              ))
            ) : error ? (
              <Alert type="error" message="Error !" />
            ) : coursesData.length === 0 ? (
              <Alert
                message={
                  search
                    ? `${t('v4:search_for')} '${search}' ${t(
                      'v4:search_no_result'
                    )}`
                    : `${t('v4:course_no_available')}`
                }
              />
            ) : (
              coursesData.map((item, index) => (
                <Container
                  key={index}
                  groupName="1"
                  behaviour="copy"
                  getChildPayload={() => item}
                >
                  <Draggable payload={item}>
                    <Card
                      type={types.COURSE}
                      data={item}
                      onClick={onCardClick}
                      tagName="course"
                      mode={courseType}
                    />
                  </Draggable>
                </Container>
              ))
            )}
          </div>
          <div className="pagination-wrapper">
            {!loading && coursesData.length > 0 && (
              <Pagination
                disabled={loading}
                total={pagination.total}
                pageSize={pagination.limit}
                onChange={handlePagination}
                current={pagination.page}
              />
            )}
          </div>{' '}
        </Col>
        {sequenceState && (
          <Col xs={24} sm={24} md={8}>
            <TrainingSequence
              seqList={seqList}
              setNewSequence={setNewSequence}
              loading={loading}
              type={types.COURSE}
              priorityLoad={priorityLoad}
            />
          </Col>
        )}
      </Row>
    </TrainingListContainer>
  )
}

export default CourseList
