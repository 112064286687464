/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-15 15:29:27
 * @LastEditTime: 2019-08-29 20:45:55
 * @LastEditors: Please set LastEditors
 */
export const SHOW_LOADING_OVERLAY = 'SHOW_LOADING_OVERLAY'
export const HIDE_LOADING_OVERLAY = 'HIDE_LOADING_OVERLAY'
export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU'

// Course builder
export const HANDLE_COURSE_INPUT = 'HANDLE_COURSE_INPUT'
export const SET_COURSE_CREATION = 'SET_COURSE_CREATION'
export const RESET_COURSE_BUILDER = 'RESET_COURSE_BUILDER'

// Maze builder
export const HANDLE_MAZE_INPUT = 'HANDLE_MAZE_INPUT'
export const SET_MAZE_CREATION = 'SET_MAZE_CREATION'
export const RESET_MAZE_BUILDER = 'RESET_MAZE_BUILDER'

// Lesson builder
export const LOAD_LESSONS = 'LOAD_LESSONS'
export const CLEAR_LESSONS = 'CLEAR_LESSONS'
export const HANDLE_LESSON_INPUT = 'HANDLE_LESSON_INPUT'
export const LOAD_SINGLE_LESSON = 'LOAD_SINGLE_LESSON'
export const RESET_LESSON_BUILDER = 'RESET_LESSON_BUILDER'
export const SET_SAVING_LESSON = 'SET_SAVING_LESSON'
export const FETCH_LESSON_REQUEST = 'FETCH_LESSON_REQUEST'
export const FETCH_LESSONS_REQUEST = 'FETCH_LESSONS_REQUEST'
export const BIND_LESSON_TO_COURSE = 'BIND_LESSON_TO_COURSE'
export const HANDLE_FILE_DROP_IN = 'HANDLE_FILE_DROP_IN'
export const HANDLE_LESSON_CLONE = 'HANDLE_LESSON_CLONE'

// Auth
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_FETCHING = 'AUTH_FETCHING'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'

// User
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILED = 'USER_FAILED'
export const USER_FETCHING = 'USER_FETCHING'
export const USER_ATTRIBUTES_FETCHING = 'USER_ATTRIBUTES_FETCHING'
export const USER_BADGE_FETCHING = 'USER_BADGE_FETCHING'
export const BADGE_GET_BY_ID = 'BADGE_GET_BY_ID'
export const USERNAME_SUCCESS = 'USERNAME_SUCCESS'

// Courses
export const LOAD_COURSES = 'LOAD_COURSES'
export const GET_COURSE_BY_ID = 'GET_COURSE_BY_ID'
export const LOAD_SCORM_COURSE_BY_ID = 'LOAD_SCORM_COURSE_BY_ID'
export const GET_SCORM_COURSE_BY_ID = 'GET_SCORM_COURSE_BY_ID'
export const REMOVE_COURSE_FROM_LIST = 'REMOVE_COURSE_FROM_LIST'
export const FETCH_LESSON_DATA_BY_ID = 'FETCH_LESSON_DATA_BY_ID'
export const GET_LESSON_DATA_BY_ID = 'GET_LESSON_DATA_BY_ID'
export const COURSE_CLOINING_PROGRESS = 'COURSE_CLOINING_PROGRESS'
export const SHOW_POP_OVER = 'SHOW_POP_OVER'
export const RESET_COURSE_BY_ID = 'RESET_COURSE_BY_ID'

export const TRAINING_COURSES_SUCCESS = 'TRAINING_COURSES_SUCCESS'
export const TRAINING_COURSES_FETCHING = 'TRAINING_COURSES_FETCHING'
export const TRAINING_COURSES_HAS_MORE = 'TRAINING_COURSES_HAS_MORE'
export const ENROLLED_COURSES_SUCCESS = 'ENROLLED_COURSES_SUCCESS'
export const ENROLLED_COURSES_FETCHING = 'ENROLLED_COURSES_FETCHING'
export const DASHBOARD_COURSES_SUCCESS = 'DASHBOARD_COURSES_SUCCESS'
export const DASHBOARD_COURSES_FETCHING = 'DASHBOARD_COURSES_FETCHING'
export const WORKSHOP_COURSES_SUCCESS = 'WORKSHOP_COURSES_SUCCESS'
export const WORKSHOP_COURSES_FETCHING = 'WORKSHOP_COURSES_FETCHING'
export const WORKSHOP_COURSES_HAS_MORE = 'WORKSHOP_COURSES_HAS_MORE'
export const WORKSHOP_ASSESSMENTS_SUCCESS = 'WORKSHOP_ASSESSMENTS_SUCCESS'
export const WORKSHOP_ASSESSMENTS_FETCHING = 'WORKSHOP_ASSESSMENTS_FETCHING'

export const USER_COURSES_FETCHING = 'USER_COURSES_FETCHING'
export const USER_COURSES_SUCCESS = 'USER_COURSES_SUCCESS'

export const WORKSHOP_ASSESSMENTS_HAS_MORE = 'WORKSHOP_ASSESSMENTS_HAS_MORE'

export const ENROLL_COURSE_SUCCESS = 'ENROLL_COURSE_SUCCESS'
export const ENROLL_COURSE_FETCHING = 'ENROLL_COURSE_FETCHING'
export const UNENROLL_COURSE_SUCCESS = 'UNENROLL_COURSE_SUCCESS'
export const UNENROLL_COURSE_FETCHING = 'UNENROLL_COURSE_FETCHING'
export const VIEW_COURSE_SUCCESS = 'VIEW_COURSE_SUCCESS'

// Organizations

export const ORGANIZATIONS_SUCCESS = 'ORGANIZATIONS_SUCCESS'
export const ORGANIZATIONS_FAILURE = 'ORGANIZATIONS_FAILURE'
export const ORGANIZATIONS_FETCHING = 'ORGANIZATIONS_FETCHING'
export const ORGANIZATIONS_AUTOCOMPLETE_SUCCESS =
  'ORGANIZATIONS_AUTOCOMPLETE_SUCCESS'
export const ORGANIZATIONS_AUTOCOMPLETE_FETCHING =
  'ORGANIZATIONS_AUTOCOMPLETE_FETCHING'
export const ORGANIZATIONS_SUCCESS_BY_ID = 'ORGANIZATIONS_SUCCESS_BY_ID'
export const ORGANIZATIONS_FETCHING_BY_ID = 'ORGANIZATIONS_FETCHING_BY_ID'
export const USERS_FETCHING_BY_ORGANIZATION_ID =
  'USERS_FETCHING_BY_ORGANIZATION_ID'
export const USERS_SUCCESS_BY_ORGANIZATION_ID =
  'USERS_SUCCESS_BY_ORGANIZATION_ID'
export const RESET_USERS_SUCCESS_BY_ORGANIZATION_ID =
  'RESET_USERS_SUCCESS_BY_ORGANIZATION_ID'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_USER_SUCCESS =
  'CREATE_ORGANIZATION_USER_SUCCESS'
export const UPDATE_ORGANIZATION_USER_SUCCESS =
  'UPDATE_ORGANIZATION_USER_SUCCESS'
export const DELETE_ORGANIZATION_USER_SUCCESS =
  'DELETE_ORGANIZATION_USER_SUCCESS'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'

// Category
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const CATEGORY_SEARCH_ORGANIZATION = 'CATEGORY_SEARCH_ORGANIZATION'
export const CATEGORY_SEARCH_ORGANIZATION_FAIL =
  'CATEGORY_SEARCH_ORGANIZATION_FAIL'
export const CATEGORY_SEARCH__ORGANIZATION_SUCCESS =
  'CATEGORY_SEARCH__ORGANIZATION_SUCCESS'

export const USERS_SEARCH_ORGANIZATION = 'USERS_SEARCH_ORGANIZATION'
export const USERS_SEARCH_ORGANIZATION_FAIL = 'USERS_SEARCH_ORGANIZATION_FAIL'
export const USERS_SEARCH__ORGANIZATION_SUCCESS =
  'USERS_SEARCH__ORGANIZATION_SUCCESS'

// Roles
export const ROLES_SUCCESS = 'ROLES_SUCCESS'
export const ROLES_FETCHING = 'ROLES_FETCHING'

// Topics
export const TOPICS_SUCCESS = 'TOPICS_SUCCESS'
export const TOPICS_FETCHING = 'TOPICS_FETCHING'
export const UPDATE_NEW_TOPIC = 'UPDATE_NEW_TOPIC'

// Library
export const LIBRARY_SUCCESS = 'LIBRARY_SUCCESS'
export const LIBRARY_FETCHING = 'LIBRARY_FETCHING'
export const PUBLIC_SUCCESS = 'PUBLIC_SUCCESS'
export const PUBLIC_FETCHING = 'PUBLIC_FETCHING'
export const UPLOAD_FETCHING = 'UPLOAD_FETCHING'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const PUT_FILE_UPLOAD_PROGRESS = 'PUT_FILE_UPLOAD_PROGRESS'
export const CLEAR_UPLOAD_LIST = 'CLEAR_UPLOAD_LIST'
export const UPDATE_LIBRARY_STATUS = 'UPDATE_LIBRARY_STATUS'
export const UPDATE_STATUS_UPLOAD = 'UPDATE_STATUS_UPLOAD'
export const HANDLE_MAKE_TYPE = 'HANDLE_MAKE_TYPE'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FETCHING = 'DOWNLOAD_FETCHING'
export const REMOVE_UPLOAD_SUCCESS = 'REMOVE_UPLOAD_SUCCESS'
export const ORG_SUCCESS = 'ORG_SUCCESS'
export const ORG_FETCHING = 'ORG_FETCHING'
export const UPDATE_ORG_LIB = 'UPDATE_ORG_LIB'
export const UPDATE_ORG_LIB_SUCCESS = 'UPDATE_ORG_LIB_SUCCESS'

// Annotation
export const ANNOTATION_FETCHING = 'ANNOTATION_FETCHING'
export const ANNOTATION_EDITOR = 'ANNOTATION_EDITOR'
export const ANNOTATION_LIST = 'ANNOTATION_LIST'
export const ANNOTATION_DELETE_BY_ID = 'ANNOTATION_DELETE_BY_ID'
export const ANNOTATION_DATA_ADD = 'ANNOTATION_DATA_ADD'
export const ANNOTATION_DATA_UPDATE = 'ANNOTATION_DATA_UPDATE'
export const ANNOTATION_GET_BY_ID = 'ANNOTATION_GET_BY_ID'
export const ANNOTATION_DATA_DEFAULT = 'ANNOTATION_DATA_DEFAULT'
export const ANNOTATION_DATA_TITLE = 'ANNOTATION_DATA_TITLE'
export const ANNOTATION_EDIT_ELEMENT = 'ANNOTATION_EDIT_ELEMENT'

// Reports
export const REPORTS_HANDLING_DATA = 'REPORTS_HANDLING_DATA'
export const FETCH_REPORT_DATA = 'FETCH_REPORT_DATA'
export const FETCH_USER_REPORT_DATA = 'FETCH_USER_REPORT_DATA'
export const GET_USER_REPORT_DATA = 'GET_USER_REPORT_DATA'

export const GET_EVENTS_REPORT_DATA = 'GET_EVENTS_REPORT_DATA'
export const FETCH_EVENTS_REPORT_DATA = 'FETCH_EVENTS_REPORT_DATA'

export const FETCH_COURSES_REPORT_DATA = 'FETCH_COURSES_REPORT_DATA'

export const FETCH_MARKET_COURSES_REPORT_DATA =
  'FETCH_MARKET_COURSES_REPORT_DATA'
export const FETCH_MARKET_WEBINAR_REPORT_DATA =
  'FETCH_MARKET_WEBINAR_REPORT_DATA'
export const FETCH_MARKET_COACHING_REPORT_DATA =
  'FETCH_MARKET_COACHING_REPORT_DATA'
export const FETCH_MARKET_CHANNEL_REPORT_DATA =
  'FETCH_MARKET_CHANNEL_REPORT_DATA'
export const FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA =
  'FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA'
export const GET_MARKET_COACHING_LIST = 'GET_MARKET_COACHING_LIST'
export const GET_MARKET_CHANNEL_LIST = 'GET_MARKET_CHANNEL_LIST'
export const GET_MARKET_PUBLIC_CONFERENCE_LIST =
  'GET_MARKET_PUBLIC_CONFERENCE_LIST'
export const GET_MARKET_MAZE_LIST = 'GET_MARKET_MAZE_LIST'

export const FETCH_MARKET_MAZE_REPORT_DATA = 'FETCH_MARKET_MAZE_REPORT_DATA'

export const GET_COURSES_REPORT_DATA = 'GET_COURSES_REPORT_DATA'
export const GET_MARKET_COURSES_REPORT_DATA = 'GET_MARKET_COURSES_REPORT_DATA'
export const GET_WORKSHOP_COURSES_REPORT_DATA =
  'GET_WORKSHOP_COURSES_REPORT_DATA'
export const FETCH_TIME_SPENT_DATA = 'FETCH_TIME_SPENT_DATA'
export const GET_USER_TIME_SPENT_REPORT_DATA = 'GET_USER_TIME_SPENT_REPORT_DATA'
export const FETCH_LESSON_REPORT_DATA = 'FETCH_LESSON_REPORT_DATA'
export const GET_LESSON_REPORT_DATA = 'GET_LESSON_REPORT_DATA'
export const FETCH_QUIZ_LIST_REPORT_DATA = 'FETCH_QUIZ_LIST_REPORT_DATA'
export const GET_QUIZ_LIST_REPORT_DATA = 'GET_QUIZ_LIST_REPORT_DATA'
export const FETCH_QUIZ_REPORT_DATA = 'FETCH_QUIZ_REPORT_DATA'
export const GET_QUIZ_REPORT_DATA = 'GET_QUIZ_REPORT_DATA'
export const FETCH_USER_SEARCH_DATA = 'FETCH_USER_SEARCH_DATA'
export const FETCH_USER_SEARCH_DATA_FAIL = 'FETCH_USER_SEARCH_DATA_FAIL'
export const FETCH_USER_SEARCH_DATA_SUCCESS = 'FETCH_USER_SEARCH_DATA_SUCCESS'
export const GET_EVENT_WORKSHOP_DATA = 'GET_EVENT_WORKSHOP_DATA'
export const FETCH_EVENT_WORKSHOP_DATA = 'FETCH_EVENT_WORKSHOP_DATA'
export const GET_COURSE_LIST = 'GET_COURSE_LIST'
export const GET_MARKET_COURSE_LIST = 'GET_MARKET_COURSE_LIST'
export const GET_MARKET_WEBINAR_LIST = 'GET_MARKET_WEBINAR_LIST'
export const FETCH_COURSE_LIST = 'FETCH_COURSE_LIST'
export const FETCH_MARKET_COURSE_LIST = 'FETCH_MARKET_COURSE_LIST'
export const GET_EVENT_LIST = 'GET_EVENT_LIST'
export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST'
export const GET_SKILL_LIST = 'GET_SKIL_LIST'
export const FETCH_SKILL_LIST = 'FETCH_SKILL_LIST'
export const FETCH_LIVE_ASSESSMENTS_LIST = 'FETCH_LIVE_ASSESSMENTS_LIST'
export const GET_LIVE_ASSESSMENTS_LIST = 'GET_LIVE_ASSESSMENTS_LIST'
export const FETCH_SKILL_JOURNEYS_REPORT_DATA =
  'FETCH_SKILL_JOURNEYS_REPORT_DATA'
export const GET_SKILL_JOURNEYS_REPORT_DATA = 'GET_SKILL_JOURNEYS_REPORT_DATA'
export const GET_SKILL_JOURNEY_WORKSHOP_DATA = 'GET_SKILL_JOURNEY_WORKSHOP_DATA'
export const FETCH_SKILL_JOURNEY_WORKSHOP_DATA =
  'FETCH_SKILL_JOURNEY_WORKSHOP_DATA'
export const FETCH_LIVE_ASSESSMENTS_REPORT_DATA =
  'FETCH_LIVE_ASSESSMENTS_REPORT_DATA'
export const GET_LIVE_ASSESSMENTS_REPORT_DATA =
  'GET_LIVE_ASSESSMENTS_REPORT_DATA'

// Events
export const TRAINING_EVENTS_SUCCESS = 'TRAINING_EVENTS_SUCCESS'
export const TRAINING_EVENTS_FETCHING = 'TRAINING_EVENTS_FETCHING'
export const TRAINING_EVENTS_WITH_ASSESSMENTS_SUCCESS =
  'TRAINING_EVENTS_WITH_ASSESSMENTS_SUCCESS'
export const TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING =
  'TRAINING_EVENTS_WITH_ASSESSMENTS_FETCHING'
export const TRAINING_EVENTS_HAS_MORE = 'TRAINING_EVENTS_HAS_MORE'
export const ENROLLED_EVENTS_SUCCESS = 'ENROLLED_EVENTS_SUCCESS'
export const ENROLLED_EVENTS_FETCHING = 'ENROLLED_EVENTS_FETCHING'
export const WORKSHOP_EVENTS_SUCCESS = 'WORKSHOP_EVENTS_SUCCESS'
export const WORKSHOP_EVENTS_FETCHING = 'WORKSHOP_EVENTS_FETCHING'
export const WORKSHOP_EVENTS_HAS_MORE = 'WORKSHOP_EVENTS_HAS_MORE'
export const WORKSHOP_PODCAST_SUCCESS = 'WORKSHOP_PODCAST_SUCCESS'
export const WORKSHOP_PODCAST_FETCHING = 'WORKSHOP_PODCAST_FETCHING'
export const WORKSHOP_PODCAST_HAS_MORE = 'WORKSHOP_PODCAST_HAS_MORE'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_PODCAST_SUCCESS = 'DELETE_PODCAST_SUCCESS'
export const PAST_EVENTS_FETCHING = 'PAST_EVENTS_FETCHING'
export const PAST_EVENTS_SUCCESS = 'PAST_EVENTS_SUCCESS'
export const WEBINAR_EVENTS_FETCHING = 'WEBINAR_EVENTS_FETCHING'
export const WEBINAR_EVENTS_SUCCESS = 'WEBINAR_EVENTS_SUCCESS'
export const NOW_LIVE_EVENTS_FETCHING = 'NOW_LIVE_EVENTS_FETCHING'
export const NOW_LIVE_EVENTS_SUCCESS = 'NOW_LIVE_EVENTS_SUCCESS'
export const PEER_TO_PEER_FETCHING = 'PEER_TO_PEER_FETCHING'
export const PEER_TO_PEER_SUCCESS = 'PEER_TO_PEER_SUCCESS'
export const CONFERENCE_FETCHING = 'CONFERENCE_FETCHING'
export const CONFERENCE_SUCCESS = 'CONFERENCE_SUCCESS'
export const VIEW_EVENT_SUCCESS = 'VIEW_EVENT_SUCCESS'

// EVENT LOG
export const FETCH_EVENT_LOG = 'FETCH_EVENT_LOG'
export const FETCH_EVENT_LOG_SUCCESS = 'FETCH_EVENT_LOG_SUCCESS'
export const FETCH_EVENT_LOG_FAIL = 'FETCH_EVENT_LOG_FAIL'

/**
 Saga types
 */

// Maze
export const CREATE_MAZE = 'CREATE_MAZE'
export const LIST_MAZES = 'LIST_MAZES'
export const GET_MAZE_BY_ID = 'GET_MAZE_BY_ID'
export const UPDATE_MAZE = 'UPDATE_MAZE'
export const DELETE_MAZE = 'DELETE_MAZE'
export const FETCH_MAZES = 'FETCH_MAZES'
export const FETCH_MAZE_BY_ID = 'FETCH_MAZE_BY_ID'
export const UPDATING_MAZE = 'UPDATING_MAZE'
export const RESET_MAZE_LIST = 'RESET_MAZE_LIST'
export const FETCH_MAZE_REPORT = 'FETCH_MAZE_REPORT'
export const GET_MAZE_REPORT = 'GET_MAZE_REPORT'
export const FETCH_MAZE_REPORT_BY_ID = 'FETCH_MAZE_REPORT_BY_ID'
export const GET_MAZE_REPORT_BY_ID = 'GET_MAZE_REPORT_BY_ID'
export const WORKSHOP_MAZES_FETCHING = 'WORKSHOP_MAZES_FETCHING'
export const WORKSHOP_MAZES_SUCCESS = 'WORKSHOP_MAZES_SUCCESS'
export const WORKSHOP_MAZES_HAS_MORE = 'WORKSHOP_MAZES_HAS_MORE'

export const WORKSHOP_CHANNELS_FETCHING = 'WORKSHOP_CHANNELS_FETCHING'
export const WORKSHOP_CHANNELS_SUCCESS = 'WORKSHOP_CHANNELS_SUCCESS'
export const WORKSHOP_CHANNELS_HAS_MORE = 'WORKSHOP_CHANNELS_HAS_MORE'

// Course builder
export const CREATE_COURSE = 'CREATE_COURSE'
export const UPDATE_COURSE = 'UPDATE_COURSE'
export const GET_LESSONS_IN_COURSE = 'GET_LESSONS_IN_COURSE'
export const DELETE_LESSON_FROM_COURSE = 'DELETE_LESSON_FROM_COURSE'
export const COURSE_BUILDER_REORDER_LESSONS = 'COURSE_BUILDER_REORDER_LESSONS'

// Lesson builder
export const FETCH_LESSONS = 'FETCH_LESSONS'
export const CREATE_LESSON = 'CREATE_LESSON'
export const FETCH_LESSON_BY_ID = 'FETCH_LESSON_BY_ID'
export const UPDATE_LESSON = 'UPDATE_LESSON'

// Courses
export const FETCH_COURSES = 'FETCH_COURSES'
export const FETCH_COURSE_BY_ID = 'FETCH_COURSE_BY_ID'
export const REMOVE_COURSE_BY_ID = 'REMOVE_COURSE_BY_ID'

// Quizes
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_QUIZES = 'FETCH_QUIZES'
export const LOAD_QUIZES = 'LOAD_QUIZES'
export const LOAD_QUIZ = 'LOAD_QUIZ'
export const FETCH_QUIZ_BY_ID = 'FETCH_QUIZ_BY_ID'
export const FETCH_QUIZ_BY_ID_ANSWERS = 'FETCH_QUIZ_BY_ID_ANSWERS'
export const LOAD_QUIZ_BY_ID = 'LOAD_QUIZ_BY_ID'
export const VALIDATE_QUIZ = 'VALIDATE_QUIZ'
export const CREATE_QUIZ = 'CREATE_QUIZ'
export const UPDATE_QUIZ = 'UPDATE_QUIZ'
export const UPDATE_QUIZES = 'UPDATE_QUIZES'
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS'
export const REMOVE_QUIZ_BY_ID = 'REMOVE_QUIZ_BY_ID'
export const LOAD_QUIZ_VALIDATE = 'LOAD_QUIZ_VALIDATE'
export const GENERATE_AI_QUIZ = 'GENERATE_AI_QUIZ'
export const TOGGLE_MODAL_QUIZ = 'TOGGLE_MODAL_QUIZ'

// Assessments
export const FETCH_REQUEST_ASSESSMENTS = 'FETCH_REQUEST_ASSESSMENTS'
export const FETCH_ASSESSMENTS = 'FETCH_ASSESSMENTS'
export const LOAD_ASSESSMENTS = 'LOAD_ASSESSMENTS'
export const FETCH_ASSESSMENT_BY_ID = 'FETCH_ASSESSMENT_BY_ID'
export const LOAD_ASSESSMENT_BY_ID = 'LOAD_ASSESSMENT_BY_ID'
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT'
export const UPDATE_ASSESSMENTS = 'UPDATE_ASSESSMENTS'
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT'
export const LOAD_ASSESSMENT = 'LOAD_ASSESSMENT'
export const REMOVE_ASSESSMENT_BY_ID = 'REMOVE_ASSESSMENT_BY_ID'
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT'
export const PAST_ASSESSMENTS_FETCHING = 'PAST_ASSESSMENTS_FETCHING'
export const PAST_ASSESSMENTS_SUCCESS = 'PAST_ASSESSMENTS_SUCCESS'
export const NOW_LIVE_ASSESSMENTS_FETCHING = 'NOW_LIVE_ASSESSMENTS_FETCHING'
export const NOW_LIVE_ASSESSMENTS_SUCCESS = 'NOW_LIVE_ASSESSMENTS_SUCCESS'
export const UPCOMING_ASSESSMENTS_FETCHING = 'UPCOMING_ASSESSMENTS_FETCHING'
export const UPCOMING_ASSESSMENTS_SUCCESS = 'UPCOMING_ASSESSMENTS_SUCCESS'

// Discussion
export const FETCH_DISCUSSION_BY_EVENT = 'FETCH_DISCUSSION_BY_EVENT'
export const FETCH_DISCUSSION_BY_EVENT_SUCCESS =
  'FETCH_DISCUSSION_BY_EVENT_SUCCESS'
export const FETCH_DISCUSSION_BY_EVENT_FAIL = 'FETCH_DISCUSSION_BY_EVENT_FAIL'
export const LOAD_DISCUSSION_BY_EVENT = 'LOAD_DISCUSSION_BY_EVENT'
export const CLEAR_DISCUSSIONS = 'CLEAR_DISCUSSIONS'
export const ADD_COMMENT = 'ADD_COMMENT'
export const POST_COMMENT = 'POST_COMMENT'
export const EDIT_COMMENT = 'EDIT_COMMENT'
export const EDIT_COMMENT_FAILURE = 'EDIT_COMMENT_FAILURE'
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL'
export const EDITING_MESSAGE = 'EDITING_MESSAGE'
export const DONE_EDITING_MESSAGE = 'DONE_EDITING_MESSAGE'
export const REPLY_TO = 'REPLY_TO'

// Notes
export const ADD_NOTE = 'ADD_NOTE'
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS'
export const ADD_NOTE_FAIL = 'ADD_NOTE_FAIL'
export const CLEAR_NOTES = 'CLEAR_NOTES'
export const FETCH_NOTES = 'FETCH_NOTES'
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS'
export const FETCH_NOTES_FAIL = 'FETCH_NOTES_FAIL'
export const EDIT_NOTE = 'EDIT_NOTE'
export const EDIT_NOTE_SUCCESS = 'EDIT_NOTE_SUCCESS'
export const EDIT_NOTE_FAIL = 'EDIT_NOTE_FAIL'
export const DELETE_NOTE = 'DELETE_NOTE'
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const EDITING_NOTES = 'EDITING_NOTES'
export const DONE_EDITING_NOTES = 'DONE_EDITING_NOTES'

/* PAGE BUILDER COMPONENTS */

// Audio Synthesizer
export const CREATE_AUDIO_SYNTHESIZER = 'CREATE_AUDIO_SYNTHESIZER'
export const PROCESSING_AUDIO_SYNTHESIZER = 'PROCESSING_AUDIO_SYNTHESIZER'
export const AUDIO_SYNTHESIZER_ERROR = 'AUDIO_SYNTHESIZER_ERROR'
export const SHOW_AUDIO_EDIT_MODAL = 'SHOW_AUDIO_EDIT_MODAL'
export const SHOW_AUDIO_DETAIL_EDIT_MODAL = 'SHOW_AUDIO_DETAIL_EDIT_MODAL'
export const GET_SELECTED_TEXT_VOICE_TYPE = 'GET_SELECTED_TEXT_VOICE_TYPE'
export const LOAD_ALL_VOICES = 'LOAD_ALL_VOICES'
export const CREATE_TTS_AUDIO_SYNTHESIZER = 'CREATE_TTS_AUDIO_SYNTHESIZER'
export const SHOW_CREATE_AUDIO_MODAL = 'SHOW_CREATE_AUDIO_MODAL'

// Text
export const SHOW_TEXT_EDIT_MODAL = 'SHOW_TEXT_EDIT_MODAL'
export const SHOW_TEXT_MODAL = 'SHOW_TEXT_MODAL'
export const SHOW_IMAGE_EDIT_MODAL = 'SHOW_IMAGE_EDIT_MODAL'
export const SHOW_VIDEO_EDIT_MODAL = 'SHOW_VIDEO_EDIT_MODAL'
export const SHOW_QUIZ_EDIT_MODAL = 'SHOW_QUIZ_EDIT_MODAL'
export const SHOW_INSERT_VIDEO_MODAL = 'SHOW_INSERT_VIDEO_MODAL'

// Annotation
export const SHOW_ANNOTATION_IMAGE_MODAL = 'SHOW_ANNOTATION_IMAGE_MODAL'
export const SHOW_ANNOTATION_TEXT_MODAL = 'SHOW_ANNOTATION_TEXT_MODAL'
export const SHOW_ANNOTATION_VIDEO_MODAL = 'SHOW_ANNOTATION_VIDEO_MODAL'
export const SHOW_ANNOTATION_AUDIO_MODAL = 'SHOW_ANNOTATION_AUDIO_MODAL'
export const SHOW_ANNOTATION_MODAL = 'SHOW_ANNOTATION_MODAL'

// Skill Journey
export const LIST_SKILL_JOURNEY = 'LIST_SKILL_JOURNEY'
export const FETCHING_SKILL_JOURNEY = 'FETCHING_SKILL_JOURNEY'
export const WORKSHOP_SKILLJOURNEYS_FETCHING = 'WORKSHOP_SKILLJOURNEYS_FETCHING'
export const WORKSHOP_SKILLJOURNEYS_SUCCESS = 'WORKSHOP_SKILLJOURNEYS_SUCCESS'
export const WORKSHOP_SKILLJOURNEYS_HAS_MORE = 'WORKSHOP_SKILLJOURNEYS_HAS_MORE'

// REVIEWS
export const GET_ALL_REVIEWS_REQUEST = 'GET_ALL_REVIEWS_REQUEST'
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS'
export const GET_ALL_REVIEWS_FAILURE = 'GET_ALL_REVIEWS_FAILURE'
export const CLEAR_REVIEWS_REQUEST = 'CLEAR_REVIEWS_REQUEST'
export const ADD_REVIEW_REQUEST = 'ADD_REVIEW_REQUEST'
export const ADD_REVIEW_SUCCESS = 'ADD_REVIEW_SUCCESS'
export const ADD_REVIEW_FAILURE = 'ADD_REVIEW_FAILURE'
export const EDIT_REVIEW_REQUEST = 'EDIT_REVIEW_REQUEST'
export const EDIT_REVIEW_SUCCESS = 'EDIT_REVIEW_SUCCESS'
export const EDIT_REVIEW_FAILURE = 'EDIT_REVIEW_FAILURE'
export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE'

export const GET_MARKET_COURSE_ANALYTICS = 'GET_MARKET_COURSE_ANALYTICS'
export const GET_MARKET_EVENT_ANALYTICS = 'GET_MARKET_EVENT_ANALYTICS'
// TRIVIA
export const LIST_QUESTIONS = 'LIST_QUESTIONS'
export const LIST_QUESTIONS_PENDING = 'LIST_QUESTIONS_PENDING'
export const LIST_QUESTION_BY_ID = 'LIST_QUESTION_BY_ID'

// SCENARIO
export const SHOW_SCENARIO_MODAL = 'SHOW_SCENARIO_MODAL'
