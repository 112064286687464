import { message } from 'antd'
import { call, put } from 'redux-saga/effects'
import { api } from '../../services'
import * as reviews from '../actions/reviews'

export function* fetchReviews(action) {
  try {
    const response = yield call(
      api.reviews.getRatings,
      action.payload.id,
      action.payload.type
    )
    yield put(reviews.getReviewsSucess(response.data))
  } catch (err) {
    message.error('Error in Fetching Ratings !!')
    yield put(reviews.addReviewsFailure())
  }
}

export function* addReviews(action) {
  try {
    const response = yield call(
      api.reviews.addRating,
      action.payload.id,
      action.payload.type,
      action.payload.data
    )
    if (response.data.errorMessage) {
      message.error(response.data.errorMessage)
      yield put(reviews.addReviewsFailure())
    } else {
      yield put(reviews.addReviewsSuccess(response.data))
      yield call(fetchReviews, { payload: action.payload });
      message.success('Successful')
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function* updateReviews(action) {
  try {
    const response = yield call(
      api.reviews.editById,
      action.payload.id,
      action.payload.data
    )
    if (response.data.errorMessage) {
      message.error(response.data.errorMessage)
    } else {
      yield put(reviews.editReviewsSuccess(response.data))
      let payload = { id: response.data.event_id, type: response.data.type }
      yield call(fetchReviews, { payload });
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}
