import React, { useState, useEffect, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { Card } from './styled'
import { getPercent } from '../../utils'
import { subscribersIcon } from './images'
import className from 'classnames'
import './Card.scss'
import { Avatar, Icon, Progress, Tag, Tooltip } from 'antd'
import defaultImage from './images/discussion.svg'

const ECard = props => {
  const {
    type,
    mode,
    data,
    horizontal,
    t,
    onClick,
    isClone,
    tagName,
    bindLesson
  } = props
  const [isProcessing, setIsProcessing] = useState(false)
  const [lessonLoading, setLessonLoading] = useState({})

  useEffect(() => {
    if (
      (data.type === 'Live Event' || data.type === 'Webinar Event') &&
      data.event_video === 'pending'
    ) {
      setIsProcessing(true)
    } else {
      setIsProcessing(false)
    }
  }, [data])

  const handleClick = () => {
    if (onClick) {
      onClick(data)
    }
  }

  const getBtnLabel = () => {
    if (isClone) {
      return 'clone'
    }
    return 'add'
  }

  const getEvent = event_type => {
    const eventType =
      event_type === 'private_conference'
        ? t('v3:private_conference')
        : event_type === 'public_conference'
        ? t('v3:public_conference')
        : event_type === 'webinar'
        ? t('v3:webinar')
        : event_type === 'discussion_room' &&
          data.discussion_room_type === 'stories'
        ? 'story'
        : event_type === 'discussion_room' &&
          data.discussion_room_type !== 'stories'
        ? 'podcast'
        : t('reports:assessment')
    return eventType
  }

  const getTag = () => {
    const isEvent = data.type === 'Live Event'
    const isChannel = data.type === 'Channel'
    return isEvent
      ? getEvent(data.event_type)
      : isChannel
      ? t('v3:channel')
      : t(`general:${tagName}`)
  }

  const src = data.cover && data.cover.resizes ? data.cover.resizes.medium : ''

  const lessonProgressPercent = getPercent(
    data.lesson_progress_count,
    data.lessons_count
  )

  const classNames = className('card', { card_horizontal: horizontal })

  const showTag =
    data.type === 'Live Assessment' ||
    data.type === 'Live Event' ||
    type === 'Course' ||
    type === 'enroll' ||
    type === 'Maze' ||
    type === 'Skill' ||
    type === 'Channel'

  console.log({ props })

  const handleLessonLoading = (lessonId, isLoading) => {
    setLessonLoading(prevState => ({
      ...prevState,
      [lessonId]: isLoading
    }))
  }
  return (
    <div className={classNames} onClick={handleClick}>
      <div className="card__thumb">
        <img
          src={src ? src : defaultImage}
          alt={data.title || data.filename}
          className="card__thumb-src"
        />
        {(mode === 'past' || mode === 'webinar') && isProcessing && (
          <Card.Overlay>{t('labels:processing')}</Card.Overlay>
        )}
        {(mode === 'past' || mode === 'webinar') &&
          data.video_links.length === 0 && (
            <Card.Overlay>
              {data.is_recording ? t('v4:unavailable') : 'Not Recorded'}
            </Card.Overlay>
          )}
        {data.start_date_time && (
          <Card.CoverDate>
            <Card.CoverDate.Day>
              {moment(data.start_date_time).format('DD')}
            </Card.CoverDate.Day>
            <Card.CoverDate.Month>
              {moment(data.start_date_time).format('MMM')}
            </Card.CoverDate.Month>
          </Card.CoverDate>
        )}

        {type === 'enroll' && lessonProgressPercent === 100 && (
          <div className="card__badge card__badge_completed">
            {t('general:completed')}
          </div>
        )}
        {type === 'enroll' && data.type === 'Live Event' && (
          <div className="card__badge card__badge_enrolled">
            {t('general:enrolled')}
          </div>
        )}
      </div>

      <div className="card__content">
        <div className="card__content-inner">
          {showTag && (
            <Card.Tags>
              <Card.Tag type="type">{getTag()}</Card.Tag>
              {(data.type === 'Live Event' ||
                data.type === 'Live Assessment') &&
                data.soon && (
                  <Card.Tag type="soon">{t('general:soon')}</Card.Tag>
                )}
            </Card.Tags>
          )}

          <div className="card__title">
            <div>{data.title || data.filename}</div>
          </div>

          {type === 'Course' && (
            <Card.Progress>
              <Card.ProgressText>
                {`${data.lesson_progress_count} / ${data.lessons_count} ${t(
                  'completed'
                )}`}
              </Card.ProgressText>

              <Progress strokeColor="green" percent={lessonProgressPercent} />
            </Card.Progress>
          )}
          {(data.start_date_time || data.start_date || data.created_at) && (
            <Tooltip
              title={
                data.type === 'Live Event' &&
                (data.start_date_time || data.start_date)
                  ? 'Started on'
                  : 'Created on'
              }
            >
              <Card.Time>
                <Icon type="calendar" />
                {moment(
                  data.start_date_time || data.start_date || data.created_at
                ).format('MMMM Do YYYY')}
              </Card.Time>
            </Tooltip>
          )}
          {data.type === 'Live Event' &&
            data.start_date_time &&
            data.event_type !== 'discussion_room' && (
              <Tooltip
                title={
                  "Event's start time -" +
                  moment(data.start_date_time).format('LLLL')
                }
              >
                <Card.ClockTime>
                  <Icon type="clock-circle" />
                  {moment(data.start_date_time).format('hh:mm A')}
                </Card.ClockTime>
              </Tooltip>
            )}
          {data.type === 'Live Event' &&
            data.start_date_time &&
            data.event_type === 'discussion_room' && (
              <Fragment>
                {/* {data.discussion_room_type !== 'custom' ? ( */}
                <Tooltip
                  title={
                    "Podcast's start time -" +
                    moment(data.start_date_time).format('LLLL')
                  }
                >
                  <Card.ClockTime>
                    <Icon type="clock-circle" />
                    {moment(data.start_date_time).format('hh:mm A')}
                  </Card.ClockTime>
                </Tooltip>
                {/* ) : (
                  <div style={{ height: 28 }}></div>
                )} */}
              </Fragment>
            )}
          {type !== 'lesson' && (
            <Card.Info>
              <Card.Creator>
                {t('general:by')}{' '}
                <Tooltip
                  title={`Created by ${
                    data.host ? data.host.username : data.organiser
                  }`}
                >
                  <Card.Creator.Name>
                    {data.host ? data.host.username : data.organiser}
                  </Card.Creator.Name>
                </Tooltip>
              </Card.Creator>
            </Card.Info>
          )}
        </div>
        {type === 'lesson' && (
          <>
            {data.creator && (
              <Card.Creator>
                {t('general:by')}{' '}
                <Card.Creator.Name>{data.creator}</Card.Creator.Name>
              </Card.Creator>
            )}

            <Card.Buttons>
              <Card.Buttons.Add
                type="primary rounded"
                loading={lessonLoading[data.id]}
                onClick={() => {
                  handleLessonLoading(data.id, true)
                  bindLesson(data.id).then(() => {
                    handleLessonLoading(data.id, false)
                  })
                }}
              >
                {t(getBtnLabel())}
              </Card.Buttons.Add>
              {false && (
                <Card.Buttons.Details ghost>
                  {t('general:details')}
                </Card.Buttons.Details>
              )}
            </Card.Buttons>
          </>
        )}
      </div>
    </div>
  )
}

export default withTranslation('card')(ECard)
