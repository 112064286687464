import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { Row, Col } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { CoursesContainer } from './styled'
import { Courses } from './internal'
import { Filter, Tag, Spinner, CloneModal } from '../../components'
import api from '../../services/api'
import CreateButtons from './internal/components/CreateButtons'
import NewHeader from '../../components/NewHeader'
import './style.scss'

const filterData = [
  { value: 'created_at', text: 'filters:date' },
  // { value: 'relevance', text: 'filters:relevance' },
  { value: 'popularity', text: 'filters:popularity' }
]

const tagsData = [
  { value: 'all', text: 'v4:tags_all' },
  { value: 'course', text: 'workshop:tags_course' },
  // { value: 'scorm', text: 'v4:tags_scorm' },
  { value: 'live_event', text: 'workshop:tags_live' },
  // { value: 'live_assessment', text: 'workshop:tags_assessment' },
  { value: 'maze', text: 'MetaMaze' },
  { value: 'skill_journey', text: 'v4:tags_skillJourney' },
  { value: 'channel', text: 'v3:channel' },
  { value: 'podcast', text: 'v3:podcast' }
]

class MyWorkshopPage extends Component {
  state = {
    order: filterData[0].value,
    tags: [tagsData[0].value],
    courseCloneTemplate: null,
    isClone: false,
    isFetchingMazeData: false
  }

  componentDidMount() {
    this.fetchData(true, 'MOUNT')
  }

  componentWillUnmount() {
    this.props.resetMazeList()
  }

  fetchData = async (isOrder, isMount) => {
    const { getWorkshopData } = this.props
    const { order } = this.state
    await getWorkshopData({ limit: 10, order }, isOrder, this.state.tags)
  }

  onFilter = order => {
    this.setState({ order }, () => this.fetchData(true))
  }

  onRemove = async item => {
    try {
      const {
        coursesActions,
        deleteEvent,
        deletePodcast,
        // deleteAssessment,
        handleMazeData
      } = this.props

      let deletionPromise

      switch (item.type) {
        case 'Course':
          deletionPromise = coursesActions.removeCourseById(item.id)
          break
        case 'Live Event':
          deletionPromise = deleteEvent(item.id)
          break
        case 'Podcast':
          deletionPromise = deletePodcast(item.id)
          break
        // case 'Live Assessment':
        //   deletionPromise = deleteAssessment(item.id)
        //   break
        case 'Maze':
          const data = {
            params: { programme_id: item.id },
            action: 'delete_programme'
          }
          deletionPromise = handleMazeData(data)
          break
        case 'Skill':
          deletionPromise = api.skillJourney.deleteSkillJourney(item.id)
          break
        case 'Channel':
          deletionPromise = api.channel.deleteChannel(item.id)
          break
        default:
          break
      }

      if (deletionPromise) {
        await deletionPromise

        this.fetchData(true)
      }
    } catch (error) {
      console.error('Error deleting:', error)
    }
  }

  onEdit = item => {
    if (item.is_cloning) return

    if (item.type === 'Course') {
      if (item.course_type === 'scorm')
        return this.props.history.push(`/scorm/${item.id}/edit`)
      return this.props.history.push(`/courses/${item.id}/edit`)
    } else if (item.type === 'Live Event') {
      return this.props.history.push(`/events/${item.id}/edit`)
    } else if (item.type === 'Podcast') {
      return this.props.history.push(`/podcast/${item.id}/edit`)
      // } else if (item.type === 'Live Assessment') {
      //   return this.props.history.push(`/assessments/${item.id}/edit`)
    } else if (item.type === 'Maze') {
      return this.props.history.push(`maze/${item.id}/edit`)
    } else if (item.type === 'Skill') {
      return this.props.history.push(`/skill-journey/edit/${item.id}`)
    } else if (item.type === 'Channel') {
      return this.props.history.push(`/channel/${item.id}/edit`)
    }
  }

  onClone = data => {
    if (data.type === 'Maze') {
      data.title = 'Copy of ' + data.title
      this.setState({
        isClone: true,
        cloneData: data
      })
    } else {
      const { courses } = this.props

      if (courses.courseCloningProgress) return

      this.setState(
        {
          isClone: true,
          cloneData: null
        },
        () => {
          this.getCourseById(data)
        }
      )
    }
  }

  getCourseById = data => {
    const { coursesActions, initCloneSocket, user } = this.props
    const { id, title } = data

    initCloneSocket({ courseId: id, user })

    coursesActions.getCourseById(id).then(({ courses_lessons }) => {
      const courseLessons = _.map(courses_lessons, ({ title, id }) => {
        const newLesson = {
          title,
          id,
          isChecked: true
        }
        return newLesson
      })
      this.setState({
        courseCloneTemplate: {
          courseId: id,
          courseTitle: `Copy of ${title}`,
          courseLessons,
          totalLesson: courseLessons.length
        }
      })
    })
  }

  handleTags = value => {
    const { tags } = this.state
    const updatedTags = tags.filter(i => i !== 'all')

    if (tags.includes(value)) {
      this.setState({
        tags: tags.filter(i => i !== value)
      })
    } else {
      if (value === 'all') {
        this.setState({
          tags: [tagsData[0].value]
        })
      } else {
        this.setState({
          tags: updatedTags.concat(value)
        })
      }
    }
  }

  checkIsAdmin = () => {
    const {
      user: { info }
    } = this.props
    return info.isSuperAdmin || info.isAdmin
  }

  render() {
    const {
      courses: {
        workshopCourses: {
          hasMore: coursesHasMore,
          list: courses,
          fetch: coursesFetch
        }
      },
      events: {
        workshopEvents: {
          hasMore: eventsHasMore,
          list: events,
          fetch: eventsFetch
        },
        workshopPodcasts: {
          hasMore: podcastsHasMore,
          list: podcasts,
          fetch: podcastsFetch
        }
      },
      // assessments: {
      //   workshopAssessments: {
      //     hasMore: assessmentsHasMore,
      //     list: assessments,
      //     fetch: assessmentsFetch
      //   }
      // },
      skillJourney: {
        workshopSkillJourneys: {
          fetch: skillJourneysFetch,
          list: skillJourneysList,
          hasMore: skillJourneysHasMore
        }
      },
      maze: {
        workshopMazes: {
          fetch: mazesFetch,
          list: mazesList,
          hasMore: mazesHasMore
        }
      },
      channel: {
        workshopChannels: {
          fetch: channelsFetch,
          list: channelsList,
          hasMore: channelsHasMore
        }
      },
      user,
      user: {
        info: { organization }
      },
      t
    } = this.props

    const { tags, cloneData, courseCloneTemplate, isClone } = this.state
    const mazeData =
      mazesList && mazesList.map(item => ({ ...item, type: 'Maze' }))
    const courseData = courses

    // const courseData =
    //   courses && courses.filter(item => item.course_type === null)
    // const scormData =
    //   courses && courses.filter(item => item.course_type === 'scorm')

    const skillsData =
      skillJourneysList &&
      skillJourneysList.map(item => ({ ...item, type: 'Skill' }))

    const channelData =
      channelsList &&
      channelsList.map(item => ({
        ...item,
        type: 'Channel'
      }))
    const includesTag = tag => tags.includes(tag) || tags.includes('all')

    var listData = [
      includesTag('course') ? courseData : [],
      // includesTag('scorm') ? scormData : [],
      includesTag('live_event') ? events : [],
      includesTag('podcast') ? podcasts : [],
      // includesTag('live_assessment') ? assessments : [],
      includesTag('skill_journey') ? skillsData : [],
      includesTag('channel') ? channelData : []
    ].flat()

    if (organization && organization.enable_maze) {
      listData = listData.concat(tags.includes('maze') ? mazeData : [])
    }

    const sortedData = listData
    // const sortedData = listData.sort((a, b) =>
    //   moment(b.created_at).diff(moment(a.created_at))
    // )

    const tagList =
      this.checkIsAdmin() && organization && organization.enable_maze
        ? tagsData
        : this.checkIsAdmin() && !(organization && organization.enable_maze)
        ? tagsData.filter(item => item.value !== 'maze')
        : _.filter(
            tagsData,
            item => item.value !== 'live_assessment' || item.value !== 'maze'
          )

    return (
      <CoursesContainer>
        <>
          <section className="section section_light workshop-wrapper">
            <Row>
              <Col span={16}>
                <div className="head">
                  <div className="head__title">{t('title')}</div>
                  <div className="head__subtitle">{t('subtitle')}</div>
                </div>
              </Col>
              {/* 
            {
              <Col span={8} style={{ textAlign: 'right' }}>
                <Button
                  className="header-button"
                  type="primary"
                  size="large"
                  icon="picture"
                  onClick={() => history.push('/library/list')}
                >
                  {t('buttons:media')}
                </Button>
              </Col>
            } */}
              <Col span={8}>
                <NewHeader />
              </Col>
              <Col span={24}>
                <div className="shl-workshop-create">
                  <CreateButtons t={t} user={user} />
                </div>
              </Col>
            </Row>
          </section>
          <section className="section workshop-head-wrapper">
            <div className="head">
              <div className="head__title">{t('courses_title')}</div>
              <div className="head__subtitle">{t('courses_subtitle')}</div>
            </div>

            <Filter
              data={filterData}
              defaultValue={filterData[0].text}
              callback={item => this.onFilter(item.value)}
              label={t('labels:sort_by')}
              bordered
            >
              {tagList.map(item => (
                <Tag
                  key={item.value}
                  text={t(item.text)}
                  value={item.value}
                  onClick={() => this.handleTags(item.value)}
                  active={tags.includes(item.value)}
                />
              ))}
            </Filter>
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => this.fetchData(false)}
              hasMore={
                coursesHasMore ||
                eventsHasMore ||
                podcastsHasMore ||
                // assessmentsHasMore ||
                skillJourneysHasMore ||
                mazesHasMore ||
                channelsHasMore
              }
              loader={
                (coursesFetch ||
                  eventsFetch ||
                  podcastsFetch ||
                  // assessmentsFetch ||
                  mazesFetch ||
                  skillJourneysFetch ||
                  channelsFetch) && <Spinner size="small" />
              }
              useWindow
              threshold={150}
            >
              <Courses
                user={user}
                data={sortedData}
                onRemove={this.onRemove}
                onEdit={this.onEdit}
                onClone={this.onClone}
              />
            </InfiniteScroll>
          </section>

          {isClone && (
            <CloneModal
              width="800px"
              visible={isClone}
              data={courseCloneTemplate || cloneData}
              app={this}
            />
          )}
        </>
      </CoursesContainer>
    )
  }
}

export default withTranslation('workshop')(MyWorkshopPage)
