import { api } from '../../services'
const generateConversation = payload => () => {
    return api.podcast
        .generateConversation(payload)
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error genrerating podcast conversation:', error)
        })
}
const getAllVoices = () => () => {
    return api.podcast
        .getAllVoices()
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error fetching voices:', error)
        })
}
const getAllMusic = () => () => {
    return api.podcast
        .getAllMusic()
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error fetching musics:', error)
        })
}
const checkPodcastStatus = (id) => () => {
    return api.podcast
        .checkPodcastStatus(id)
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error fetching voices:', error)
        })
}
const generatePodcastAudio = (payload) => () => {
    return api.podcast
        .generatePodcastAudio(payload)
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error generating audio:', error)
        })
}
const createVoice = (payload) => () => {
    return api.podcast
        .createVoice(payload)
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error generating voice:', error)
        })
}
const deleteVoice = (payload) => () => {
    return api.podcast
        .deleteVoice(payload)
        .then(response => {
            return response
        })
        .catch(error => {
            console.error('Error deleting voice:', error)
        })
}
export {
    getAllMusic,
    deleteVoice,
    createVoice,
    generateConversation,
    getAllVoices,
    generatePodcastAudio,
    checkPodcastStatus
}
