import React, { useState, useEffect, Fragment } from 'react'
import {
  ReportTable,
  HeaderTabs,
  CustomTabPane,
  PaginationRightAligned,
  SearchEventMargin
} from '../../../Reports/styled'
import { Icon, Tabs, Row, Pagination, Col, Divider } from 'antd'
import { Search } from '../../../../components'
import { generateCSV } from '../../../../utils'
import moment from 'moment'
import { isSafari } from 'react-device-detect'
import { report } from '../../../../constants'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import ReportHeader from '../../../Reports/components/ReportHeader'
import MarketplaceTypeSelect from '../../../Reports/components/MarketplaceTypeSelect'
import { getEventOwnerReport } from '../../../../services/api/reports'
import history from '../../../../history'

const { TabPane } = Tabs

const columns = t => [
  {
    title: t('v2:item'),
    dataIndex: 'cover',
    render: cover => {
      return (
        <img
          style={{ width: '70px', height: '50px' }}
          src={cover.resizes && cover.resizes.thumbnail}
          alt="cover"
        />
      )
    },
    width: 70
  },
  {
    dataIndex: 'title',
    textWrap: 'word-break'
  },

  {
    title: t('reports:created_date'),
    dataIndex: 'created_at',
    render: x => {
      return moment(x).format('ll')
    },
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('v3:marketplace_price'),
    dataIndex: 'price',
    sorter: (a, b) => {
      const priceA = a.price ? parseFloat(a.price) : 0
      const priceB = b.price ? parseFloat(b.price) : 0
      return priceA - priceB
    },
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('v3:edit_price'),
    dataIndex: 'edit_price',
    sorter: (a, b) => a.edit_price - b.edit_price,
    sortDirections: ['descend', 'ascend']
  },

  {
    title: t('v3:quantity'),
    dataIndex: 'quantity',
    sorter: (a, b) => a.quantity - b.quantity,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('v3:total_amount'),
    dataIndex: 'total_amount',
    sorter: (a, b) => a.total_amount - b.total_amount,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: t('v3:net_amount'),
    dataIndex: 'net_amount',
    sorter: (a, b) => a.net_amount - b.net_amount,
    sortDirections: ['descend', 'ascend']
  }
]

const reverseDateType = {
  5: 'all',
  1: 'today',
  7: 'week',
  30: 'month',
  12: 'year',
  2: 'other'
}

const Preview = props => {
  const [page, setPage] = useState(report.PAGE_NUMBER)
  const [pageSize] = useState(report.PAGE_SIZE)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [workshopDateType, setWorkshopDateType] = useState('5')
  const [workshopSearchType] = useState('')
  const [dateTo, setDateTo] = useState(null)
  const [dateFrom, setDateFrom] = useState(null)
  const [marketLoading, setMarketLoading] = useState(true)
  const [list, setList] = useState([])
  const [totalPagination, setTotalPagination] = useState(0)
  const [courses, setCourses] = useState([])

  useEffect(() => {
    const reportObj = {
      type: 'event',
      event_type: 'coaching_session',
      offset: createOffset(page, pageSize),
      limit: pageSize,
      date: 'all',
      search: '',
      from: '',
      to: ''
    }
    callreportFunc(reportObj)
  }, [])

  const callreportFunc = async reportObj => {
    try {
      const res = await getEventOwnerReport(reportObj)

      setList(res.data.events)
      setCourses(res.data.events)
      setTotalPagination(res.data.pagination.total)
      setMarketLoading(false)
    } catch (error) {
      console.error(error)
      setMarketLoading(false)
    }
  }

  const download = () => {
    const coursesArray = list || []
    const dataCSV = coursesArray.map(x => ({
      id: x.id,
      title: x.title,
      created_at: moment(x.created_at).format('YYYY-MM-DD'),
      price: x.price,
      edit_price: x.edit_price,
      quantity: x.quantity,
      total_amount: x.total_amount,
      net_amount: x.net_amount,
      total: x.total
    }))
    const header = [
      '#',
      'Title',
      'Created at',
      'Edit Price',
      'Quantity',
      'Total Amount',
      'Net Amount',
      'Total'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'coaching-report.csv')
      document.body.appendChild(ele)
      ele.click()
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'coaching-report.csv')
      ele.click()
    }
  }

  const createOffset = (page, pageSize) => page * pageSize - pageSize

  const onChangePagination = async page => {
    setMarketLoading(true)

    const requestOptions = {
      type: 'event',
      event_type: 'coaching_session',
      offset: createOffset(page, pageSize),
      limit: pageSize,
      ...(workshopSearchType && {
        date: workshopSearchType === 'allTime' ? 'all' : workshopSearchType
      }),
      search: search,
      date:
        reverseDateType[workshopDateType] === 'other'
          ? ''
          : reverseDateType[workshopDateType],
      from: reverseDateType[workshopDateType] === 'other' ? dateFrom : '',
      to: reverseDateType[workshopDateType] === 'other' ? dateTo : ''
    }

    setPage(page)

    try {
      const res = await getEventOwnerReport(requestOptions)
      setList(res.data.events)
      setCourses(res.data.events)

      setTotalPagination(res.data.pagination.total)
      setMarketLoading(false)
    } catch (error) {
      console.error(error)
      setMarketLoading(false)
    }
  }

  const onSearch = _.debounce(value => {
    setMarketLoading(true)
    setSearch(value)
    const requestOptions = {
      type: 'event',
      event_type: 'coaching_session',
      offset: createOffset(1, pageSize),
      limit: pageSize,
      ...(workshopSearchType && {
        date: workshopSearchType === 'allTime' ? 'all' : workshopSearchType
      }),
      search: value,
      title: value,
      date:
        reverseDateType[workshopDateType] === 'other'
          ? ''
          : reverseDateType[workshopDateType],
      from: reverseDateType[workshopDateType] === 'other' ? dateFrom : '',
      to: reverseDateType[workshopDateType] === 'other' ? dateTo : ''
    }
    setPage(1)

    try {
      const marketFilter = async () => {
        const res = await getEventOwnerReport(requestOptions)
        setList(res.data.events)
        setCourses(res.data.events)

        setTotalPagination(res.data.pagination.total)
        setMarketLoading(false)
      }
      marketFilter()
    } catch (error) {
      console.error(error)
      setMarketLoading(false)
    }
  }, 1000)

  const handleSortByChange = async value => {
    setMarketLoading(true)

    const defaultProps = {
      type: 'event',
      event_type: 'coaching_session',
      offset: createOffset(page, pageSize),
      limit: pageSize,
      date: 'today',
      search: search,
      from: '',
      to: ''
    }

    if (value === '1') {
      setSortBy('today')
      setWorkshopDateType(value)

      try {
        const res = await getEventOwnerReport({
          ...defaultProps
        })
        setCourses(res.data.events)
        setTotalPagination(res.data.pagination.total)
      } catch (error) {
        console.error(error)
      }
    } else if (value === '7') {
      setSortBy('week')
      setWorkshopDateType(value)

      try {
        const res = await getEventOwnerReport({
          ...defaultProps,
          date: 'week'
        })
        setCourses(res.data.events)
        setTotalPagination(res.data.pagination.total)
      } catch (error) {
        console.error(error)
      }
    } else if (value === '30') {
      setSortBy('month')
      setWorkshopDateType(value)

      try {
        const res = await getEventOwnerReport({
          ...defaultProps,
          date: 'month'
        })
        setCourses(res.data.events)
        setTotalPagination(res.data.pagination.total)
      } catch (error) {
        console.error(error)
      }
    } else if (value === '12') {
      setSortBy('year')
      setWorkshopDateType(value)

      try {
        const res = await getEventOwnerReport({
          ...defaultProps,
          date: 'year'
        })
        setCourses(res.data.events)
        setTotalPagination(res.data.pagination.total)
      } catch (error) {
        console.error(error)
      }
    } else if (value === '5') {
      setSortBy('all')
      setWorkshopDateType(value)

      try {
        const res = await getEventOwnerReport({
          ...defaultProps,
          date: 'all'
        })
        setCourses(res.data.events)
        setTotalPagination(res.data.pagination.total)
      } catch (error) {
        console.error(error)
      }
    } else if (value === '2') {
      setSortBy('other')
      setWorkshopDateType(value)
    }
    setMarketLoading(false)
  }

  const handleDate = val => {
    setDateFrom(val[0])
    setDateTo(val[1])
    fetchData()
  }

  const fetchData = async () => {
    setMarketLoading(true)

    const requestOptions = {
      type: 'event',
      event_type: 'coaching_session',
      offset: createOffset(page, pageSize),
      limit: pageSize,
      date: '',
      from: dateFrom,
      to: dateTo,
      search: search
    }

    try {
      const res = await getEventOwnerReport(requestOptions)
      setMarketLoading(false)
      setCourses(res.data.events)
      setTotalPagination(res.data.pagination.total)
    } catch (error) {
      console.error(error)
      setMarketLoading(false)
    }
  }

  const callbackTabClicked = (key, event) => {
    if (key === '0') {
      history.push(`/reports`, 'marketplace')
    } else if (key === '1') {
      history.push('/reports')
    } else if (key === '2') {
      history.push('/reports', 'user')
    }
  }

  const {
    t,
    user: {
      info: { organization }
    }
  } = props

  const lang = localStorage.getItem('i18nextLng')

  return (
    <Fragment>
      <div className="content-warp">
        <div
          className="report-warp sharelook-table"
          style={{ direction: 'ltr' }}
        >
          <HeaderTabs
            defaultActiveKey="9"
            animated={false}
            // onChange={changeTab}
            onTabClick={callbackTabClicked}
            tabBarExtraContent={
              <ReportHeader
                workshopDateType={workshopDateType}
                dateChange={value => handleSortByChange(value)}
                download={download}
                handleDate={(date, dateString) => handleDate(dateString)}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          >
            <TabPane
              tab={
                <CustomTabPane>
                  <Icon type="left" style={{ fontSize: '18px' }} />
                </CustomTabPane>
              }
              key="0"
            />
            <TabPane tab={t('reports:workshop_report')} key="1"></TabPane>
            <TabPane tab={t('reports:user_report')} key="2"></TabPane>
            <TabPane
              className="sharelok-marketplace-report"
              tab={t('v4:marketplace_report')}
              key="9"
              style={{
                direction:
                  lang === 'hebrew' || lang === 'arabic' ? 'rtl' : 'ltr'
              }}
            >
              <React.Fragment>
                <MarketplaceTypeSelect defaultValue="coaching" />

                {/* {!hasFetched ? (
                  <Spinner />
                ) : ( */}
                <Row>
                  <ReportTable
                    rowKey="id"
                    columns={columns(t)}
                    dataSource={courses}
                    pagination={false}
                    loading={marketLoading}
                    borderRadius
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: e =>
                          history.push(
                            `/reports/market-place/coaching/` + record.id
                          )
                      }
                    }}
                    title={() => (
                      <>
                        <Row
                          className="sharelook-course-wrapper"
                          style={{ marginLeft: '30px', marginTop: '3px' }}
                        >
                          <Col span={5}>
                            <SearchEventMargin>
                              <Search
                                placeholder={t('v3:search_coaching')}
                                onChange={e => onSearch(e.target.value)}
                              />
                            </SearchEventMargin>
                          </Col>
                          <Col span={11} />
                          <Col className="report-title" span={3} push={1}>
                            {t('v4:total')} {totalPagination} {''}
                            {t('v3:report_coaching')}
                          </Col>
                          <Col span={4} style={{ marginTop: '14px' }}>
                            <PaginationRightAligned margin>
                              <Pagination
                                total={totalPagination}
                                current={page}
                                pageSize={pageSize}
                                onChange={onChangePagination}
                                simple
                              />
                            </PaginationRightAligned>
                          </Col>
                        </Row>
                        <Divider style={{ margin: '0px' }} />
                      </>
                    )}
                  />
                </Row>
                {/* )} */}
              </React.Fragment>
            </TabPane>
          </HeaderTabs>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('report')(Preview)
