import React, { useState, useEffect } from 'react'
import { TopPageLink, Reviews, ReviewsButtonModal } from '../../../components'
import calsvg from './assets/calender.svg'
import moment from 'moment'
import userImg from '../../../assets/user.svg'
import searchSvg from './assets/search.svg'
import {
  PlayCircleFilled,
  ReloadOutlined,
  DownloadOutlined,
  LoadingOutlined
} from '@ant-design/icons'

import {
  Divider,
  Button,
  Tooltip,
  Col,
  Row,
  Spin,
  Alert,
  Tabs,
  Input,
  Modal,
  Rate,
  message
} from 'antd'
import { api } from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './DiscussionListen.scss'
import sencSVG from './assets/send.svg'
import AudioPlayer from './AudioPlayer'
import {
  addReviewsRequest,
  getReviewsRequest
} from '../../../store/actions/reviews'
import fileSaver from 'file-saver'

const DiscussionListen = ({ match, user, location }) => {
  const { t } = useTranslation()
  const reviews = useSelector(state => state.reviews.reviews)
  const avgRating = useSelector(state => state.reviews.avg_rating)
  const dispatch = useDispatch()
  const [userId] = useState(user.info.id)
  const [eventId] = useState(match.params.id)
  const [isLoading, setIsLoading] = useState(false)
  const [isDownLoading, setIsDownLoading] = useState({
    status: false,
    id: '',
    title: ''
  })
  const [hasReviewed, setHasReviewed] = useState(true)
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [isError, setIsError] = useState({ status: false, message: '' })
  const [audioLink, setAudioLink] = useState('')
  const [reviewText, setReviewText] = useState({ text: '', value: null })
  const [reviewStarModal, setReviewStarModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [tab, setActiveTab] = useState('OVERVIEW')
  const [podcastHost, setPodcastHost] = useState([])
  const [podcastAudio, setPodcastAudio] = useState({
    list: [],
    allList: [],
    status: false
  })
  const [podcastList, setPodcastList] = useState([])
  const [podcastData, setPodcastData] = useState({
    cover: { link: '' },
    id: '',
    title: '',
    start_date_time: null,
    description: '',
    avg_rating: null
  })
  const [attachedData, setAttachedData] = useState({
    filenames: [],
    attachmentIds: []
  })
  useEffect(() => {
    getEventById(eventId)
    getRating(eventId)
  }, [])
  useEffect(() => {
    let filteredList = podcastAudio.allList.filter(el =>
      el.title.toLowerCase().includes(searchText.toLowerCase())
    )
    setPodcastAudio({ ...podcastAudio, list: filteredList })
  }, [searchText])
  useEffect(() => {
    if (reviews && reviews.length > 0) {
      const checkreviews = reviews.filter(item => item.user_id === userId)
      if (checkreviews.length <= 0) {
        setHasReviewed(false)
      } else {
        setHasReviewed(true)
      }
    } else {
      setHasReviewed(false)
    }
  }, [reviews])
  const getEventById = async (id, isRefresh) => {
    setIsLoading(!isRefresh)
    api.events
      .getById(id)
      .then(event => {
        setPodcastData({
          cover: event.data.cover,
          id: event.data.id,
          title: event.data.title,
          description: event.data.description,
          avg_rating: event.data.avg_rating
        })
        getAttachmentDetail(event.data.attachments)
        if (event.data.hosts) {
          setPodcastHost(event.data.hosts)
        }
        if (event.data.audio[0]) {
          // // sorting according to title
          // let audioList = event.data.audio.sort((a, b) =>
          //   a.title.localeCompare(b.title)
          // )
          setAudioData(event.data.audio)
        }
        setIsLoading(false)
      })
      .catch(error => {
        let text = 'Failed to get podcast data!'
        let type = 'Not Found!'
        try {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorMessage &&
            error.response.data.errorType
          ) {
            text = error.response.data.errorMessage
            type = error.response.data.errorType
          }
        } catch (e) {}
        message.error(text)
        setIsError({
          status: true,
          message: text,
          type: type
        })
        setIsLoading(false)
      })
  }
  const getAttachmentDetail = async ids => {
    if (ids && ids.length > 0) {
      let filterNames = []
      await Promise.all(
        ids.map(async id => {
          const response = await api.library.getMediaInfoById(id)
          filterNames.push(response.data.filename)
        })
      )
      setAttachedData({
        attachmentIds: ids,
        filenames: filterNames
      })
    }
  }
  const getRating = async id => {
    await dispatch(getReviewsRequest(id, 'event'))
  }

  const onChange = key => {
    setActiveTab(key)
  }
  const onReviewInput = e => {
    setReviewText({ ...reviewText, text: e.target.value })
  }
  const sendReview = () => {
    if (reviewText.text.length > 0) {
      setReviewStarModal(true)
    }
  }
  const saveReview = async () => {
    setIsReviewLoading(true)
    const data = {
      review: reviewText.text,
      rating: reviewText.value
    }
    await dispatch(addReviewsRequest(eventId, 'event', data))
    setReviewText({ text: '', value: null })
    setIsReviewLoading(false)
    setReviewStarModal(false)
  }
  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  const handleSelectAudio = (audio, isNext) => {
    if (isNext) {
      podcastList.forEach((el, i) => {
        if (el.id === isDownLoading.id) {
          audio = podcastList[i + 1 >= podcastList.length ? 0 : i + 1]
        }
      })
    }
    if (audio.id !== isDownLoading.id) {
      setPodcastData({
        ...podcastData,
        cover: audio.cover,
        title: audio.title,
        description: audio.description
      })

      setAudioLink(audio.downloadble_link)
      setIsDownLoading({ status: true, id: audio.id, title: audio.title })

      setTimeout(() => {
        setIsDownLoading({ status: false, id: audio.id, title: audio.title })
      }, 300)
    }
  }

  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }
  const setAudioData = async data => {
    try {
      setPodcastAudio({ list: [], allList: [], status: true })
      let audioList = await Promise.all(
        data.map(async audio => {
          try {
            let res = await getDuration(audio.id)
            // if (i === 0) {
            //   /// playing the first audio default
            //   setIsDownLoading({
            //     status: false,
            //     id: audio.id,
            //     title: audio.title
            //   })
            //   setAudioLink(res.downloadble_link)
            // }
            return {
              ...audio,
              duration: res.duration,
              downloadble_link: res.downloadble_link
            }
          } catch (error) {
            return {
              ...audio,
              duration: 0,
              downloadble_link: ''
            }
          }
        })
      )
      setPodcastAudio({ list: audioList, allList: audioList, status: false })
      setPodcastList(audioList)
    } catch (error) {
      setPodcastAudio({ list: [], allList: [], status: false })
      console.error('Error processing audio data:', error)
    }
  }

  const getDuration = id => {
    return new Promise((resolve, reject) => {
      api.library
        .download({ id })
        .then(({ data }) => {
          getAudioDuration(data.downloadble_link)
            .then(duration => {
              resolve({
                duration: duration,
                downloadble_link: data.downloadble_link
              })
            })
            .catch(e => {
              reject(e)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const getAudioDuration = async url => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url)
      audio.addEventListener('loadedmetadata', () => {
        resolve(formatTime(audio.duration))
      })

      audio.addEventListener('error', error => {
        reject(error)
      })
    })
  }
  const downloadFile = (id, name) => {
    setIsDownLoading({ ...isDownLoading, status: true, id: id })
    api.library
      .download({ id })
      .then(({ data }) => {
        fileSaver.saveAs(data.downloadble_link, name)
        setIsDownLoading({ ...isDownLoading, status: false, id: '' })
      })
      .catch(() => {
        message.error('Failed to download!')
        setIsDownLoading({ ...isDownLoading, status: false, id: '' })
      })
  }
  const skillJourney = location.state && location.state.skillJourney
  const skillJourneyId = location.state && location.state.skillJourneyId
  return (
    <>
      <TopPageLink
        page={skillJourney ? t('v4:skill') : t('navigation:training')}
        path={skillJourney ? `/skill-journey/${skillJourneyId}` : '/training'}
      />
      {reviewStarModal && (
        <Modal
          title={null}
          visible={reviewStarModal}
          onCancel={() => setReviewStarModal(false)}
          footer={null}
        >
          <div className="podcast_rating">
            <div className="podcast_rating_title">
              <h1>{t('v3:howRate')}</h1>
            </div>
            <div className="podcast_rating_text">
              <p>{reviewText.text}</p>
            </div>
            <div>
              <Rate
                className="podcast_rating_value"
                value={reviewText.value}
                onChange={value => {
                  setReviewText({ ...reviewText, value: value })
                }}
              ></Rate>
            </div>
            <div>
              <Button
                className="rating__btn"
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                block
                onClick={e => {
                  e.preventDefault()
                  saveReview()
                }}
                loading={isReviewLoading}
              >
                {t('v3:podcastSubmit')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Spin spinning={isLoading}>
        {isError.status ? (
          <div className="podcast-error">
            <Alert
              showIcon
              message={isError.type}
              description={isError.message}
              type="error"
            />
          </div>
        ) : (
          <>
            <div className="podcast-play">
              <div className="podcast-play_body">
                <Row justify="center" align="middle" gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={16} lg={16}>
                    <div className="lsection-col">
                      <Row
                        justify="space-between"
                        type="flex"
                        gutter={[16, 16]}
                      >
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center'
                          }}
                        >
                          <div className="podcast-play_body_guestheading">
                            {t('v3:podcastGuest')}
                          </div>
                          <div className="podcast-play_body_avatar">
                            {podcastHost.map((host, index) => {
                              return (
                                <Tooltip
                                  key={index + 'g'}
                                  title={host.first_name + ' ' + host.last_name}
                                >
                                  <div className="avatar-circle">
                                    <img
                                      src={
                                        host.avatar ? host.avatar.link : userImg
                                      }
                                      alt={host.user_name}
                                    />
                                  </div>
                                </Tooltip>
                              )
                            })}
                          </div>
                        </Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center'
                          }}
                        >
                          <ReviewsButtonModal
                            avgRating={avgRating}
                            id={eventId}
                            userId={userId}
                            podcast={true}
                            type="event"
                          />
                        </Col>
                      </Row>

                      <div className="podcast-play_body_lsection">
                        <div
                          className="podcast-play_body_lsection_cover"
                          style={{
                            backgroundImage: `url(${
                              podcastData.cover ? podcastData.cover.link : ''
                            })`
                          }}
                        >
                          {isDownLoading.id && (
                            <div className="podcast-play_body_lsection_player">
                              {audioLink && (
                                <AudioPlayer
                                  audioName={isDownLoading.title}
                                  audioUrl={audioLink}
                                  autoPlayNext={() => {
                                    handleSelectAudio(
                                      { id: isDownLoading.id },
                                      true
                                    )
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="podcast-play_body_tabs">
                        <Tabs
                          onChange={onChange}
                          activeKey={tab}
                          animated={false}
                        >
                          <Tabs.TabPane
                            tab={t('tabs:overview')}
                            key="OVERVIEW"
                          />
                          <Tabs.TabPane tab={t('v4:reviews')} key="REVIEW" />
                          {attachedData.attachmentIds.length !== 0 && (
                            <Tabs.TabPane
                              tab={t('v4:tab_attachments')}
                              key="ATTACHMENT"
                            />
                          )}
                        </Tabs>
                        <Divider style={{ margin: 0 }} />
                        {tab === 'OVERVIEW' && (
                          <div className="podcast-play_body_tabs_overview">
                            <div className="podcast-play_body_tabs_overview_title">
                              {podcastData.title}
                            </div>
                            <div className="podcast-play_body_tabs_overview_datetime">
                              <img
                                src={calsvg}
                                alt="calsvg"
                                className="calsvg-icon"
                              />
                              &nbsp;
                              {moment(podcastData.start_date_time).format(
                                'L'
                              )}{' '}
                              {' : '}
                              {moment(podcastData.start_date_time).format('LT')}
                            </div>
                            <div className="description-output">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    podcastData.description ||
                                    'Description unavailable for this podcast.'
                                }}
                              />
                            </div>
                            {/* <div>
                              <Paragraph
                                className="podcast-play_body_tabs_overview_desc"
                                ellipsis={{
                                  rows: 3,
                                  expandable: true,
                                  symbol: 'more'
                                }}
                                onClick={() => setExpanded(!expanded)}
                                style={{ cursor: 'pointer' }}
                              >
                                {podcastData.description}
                              </Paragraph>
                            </div> */}
                          </div>
                        )}
                        {tab === 'ATTACHMENT' && (
                          <div className="podcast-play_body_tabs_overview">
                            {attachedData.filenames.map((name, i) => {
                              return (
                                <div className="discussion_room-sidebar__attachement_body">
                                  <div>
                                    <b>{i + 1}.&nbsp;</b>
                                    {name}
                                  </div>
                                  <div>
                                    <Tooltip
                                      placement="topLeft"
                                      title={t('v4:fileDownload')}
                                      arrow
                                    >
                                      {isDownLoading.status &&
                                      isDownLoading.id ===
                                        attachedData.attachmentIds[i] ? (
                                        <ReloadOutlined
                                          spin
                                          style={{
                                            fontSize: 20,
                                            color: '#6AB2FF',
                                            margin: '5px',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      ) : (
                                        <DownloadOutlined
                                          onClick={() => {
                                            downloadFile(
                                              attachedData.attachmentIds[i],
                                              name
                                            )
                                          }}
                                          style={{
                                            fontSize: 20,
                                            color: '#52C41A',
                                            margin: '5px',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      )}
                                    </Tooltip>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )}
                        {tab === 'REVIEW' && (
                          <div className="podcast-reviews">
                            <div className="podcast-reviews-body">
                              {!hasReviewed && (
                                <div>
                                  <Input
                                    placeholder="Say something..."
                                    defaultValue={reviewText.text}
                                    onChange={onReviewInput}
                                    onPressEnter={sendReview}
                                    allowClear
                                    suffix={
                                      isReviewLoading ? (
                                        <Spin
                                          indicator={<LoadingOutlined spin />}
                                        />
                                      ) : (
                                        <img
                                          src={sencSVG}
                                          alt="send"
                                          style={{ cursor: 'pointer' }}
                                          onClick={sendReview}
                                        />
                                      )
                                    }
                                  />
                                </div>
                              )}
                              {reviews.length > 0 && (
                                <>
                                  <div className="podcast-reviews-body-title">
                                    {t('v3:podcastReview')} ({reviews.length})
                                  </div>
                                  <Reviews
                                    id={eventId}
                                    userId={userId}
                                    type="event"
                                    podcast={true}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Spin spinning={podcastAudio.status}>
                      <div className="rsection-col">
                        <div className="podcast-play_body_rsection">
                          <div className="podcast-play_body_rsection_title">
                            {podcastAudio.list.length}{' '}
                            {podcastAudio.list.length === 1
                              ? t('v4:singleEpishode')
                              : t('v3:podcastEpishode')}
                          </div>
                          <div className="podcast-play_body_rsection_search">
                            <Input
                              placeholder="Search"
                              allowClear
                              onChange={handleSearch}
                              suffix={
                                !searchText && (
                                  <img src={searchSvg} alt="search" />
                                )
                              }
                            />
                          </div>
                        </div>
                        {podcastAudio.list.map((audio, i) => {
                          return (
                            <div
                              className={`podcast-play_body_content ${isDownLoading.id ===
                                audio.id &&
                                'podcast-play_body_content_active'}`}
                              key={i}
                              onClick={e => {
                                e.preventDefault()
                                handleSelectAudio(audio, false)
                              }}
                            >
                              <Row
                                justify="space-between"
                                align="middle"
                                gutter={[8, 8]}
                              >
                                <Col span={20} style={{ textAlign: 'left' }}>
                                  <div className="podcast-play_body_content_name">
                                    {audio.title}
                                  </div>
                                  <div className="podcast-play_body_content_time">
                                    {audio.duration} mins
                                  </div>
                                </Col>
                                <Col span={4} style={{ textAlign: 'right' }}>
                                  {isDownLoading.status &&
                                    isDownLoading.id === audio.id && (
                                      <ReloadOutlined
                                        spin
                                        style={{
                                          fontSize: 30,
                                          color: '#00000054',
                                          margin: '5px'
                                        }}
                                      />
                                    )}
                                  {!isDownLoading.status && (
                                    <PlayCircleFilled
                                      style={{
                                        fontSize: 30,
                                        color: '#00000054',
                                        margin: '5px'
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          )
                        })}
                      </div>
                    </Spin>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </Spin>
    </>
  )
}
export default DiscussionListen
