import React from 'react'
import { Col, Row, Spin } from 'antd'
import PropTypes from 'prop-types'

const ReportCountItem = ({
  loading,
  totalUsers,
  onClick,
  iconSrc,
  title,
  isBluishBackground
}) => {
  return (
    <Col span={11}>
      <div
        className={`report-count-container ${
          isBluishBackground ? 'bluish-background' : ''
        }`}
        onClick={() => (loading ? null : onClick())}
      >
        <div className="report-count-info-user">
          <div className="count-user">
            <img alt={title} src={iconSrc} />
            <h1>{loading && !totalUsers ? <Spin /> : totalUsers}</h1>
            <h3>{title.toUpperCase()}</h3>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default ReportCountItem

ReportCountItem.propTypes = {
  userType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  totalUsers: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
