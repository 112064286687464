import React from 'react'
import { CreateChannelForm } from './components'
import { connect } from 'react-redux'
import './CreateChannel.scss'
const mapStateToProps = state => ({
  user: state.user
})
function CreateChannel(props) {
  const id = props.match.params.id

  const state = props.location.state || null
  const trainerInfo = props.user
  return (
    <div className="shl-create-channel">
      <CreateChannelForm channelId={id} newAiVideo={state} trainerInfo={trainerInfo} />
    </div>
  )
}
export default connect(mapStateToProps)(CreateChannel)
