import React, { Component, Fragment } from 'react'
import {
  // CustomSelect,
  CustomTabs,
  PaginationRightAligned,
  ReportTable,
  ReportSelect,
  BackButton
} from '../../styled.js'
import { LeftOutlined } from '@ant-design/icons'
import './styles.scss'
import { Spinner, Search } from '../../../../components'
import {
  Tabs,
  Icon,
  Row,
  Col,
  Avatar,
  Card,
  Divider,
  Pagination,
  Alert,
  Select
} from 'antd'
import ReactEcharts from 'echarts-for-react'
import { generateCSV } from '../../../../utils'
import moment from 'moment'
import { userImage, userImageNoBack } from '../../images'
import { isSafari } from 'react-device-detect'

import { withTranslation } from 'react-i18next'
import ReportHeader from '../../components/ReportHeader.jsx'
import TypeSelect from '../../components/TypeSelect.jsx'
import { report } from '../../../../constants'
import { api } from '../../../../services'

const { TabPane } = Tabs

const getMinutes = (startTimeStamp, endTimeStamp) => {
  const formatStartTimeStamp = moment(startTimeStamp).format('YYYY-MM-DD HH:mm')
  const formatEndTimeStamp = moment(endTimeStamp).format('YYYY-MM-DD HH:mm')
  const startTime = moment(formatStartTimeStamp)
  const endTime = moment(formatEndTimeStamp)
  const duration = moment.duration(endTime.diff(startTime))
  return duration.format('hh:mm:ss', { trim: false })
}

const columns = t => [
  {
    title: t('reports:participant'),
    dataIndex: 'avatar',
    render: record => (
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <img
          alt=""
          src={record && record.link ? record.link : userImage}
          style={{
            objectFit: 'contain',
            height: '50px',
            width: '50px',
            borderRadius: '50%',
            border: '2px solid #c4c4c4'
          }}
        />
      </div>
    )
  },
  {
    textWrap: 'word-break',
    dataIndex: 'username'
  },
  {
    title: t('v4:group'),
    dataIndex: 'group'
  },
  {
    title: t('reports:join_leave_date'),
    dataIndex: 'join_time/leave_time',
    render: (text, record) => {
      return `${
        record.join_time !== null
          ? moment(record.join_time).format('HH:mm')
          : '- -'
      }
        /
        ${
          record.leave_time !== null
            ? moment(record.leave_time).format('HH:mm')
            : '- -'
        }`
    }
  },
  {
    title: t('v4:watched_time_mins'),
    dataIndex: 'watched_time',
    render: (text, record) => {
      return record.join_time === null || record.leave_time === null
        ? '- -'
        : getMinutes(record.join_time, record.leave_time)
    }
  },
  {
    title: t('reports:poll'),
    dataIndex: 'polls',
    render: polls => {
      return polls.length
    }
  },
  {
    title: t('reports:comment'),
    dataIndex: 'comments',
    render: comments => {
      return comments.length
    }
  },
  {
    title: t('v4:detail')
  }
]

let expandSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F0F2F5" />
    <path d="M4 6L8.5 11L13 6" stroke="#246CF6" />
  </svg>
)

class Preview extends Component {
  state = {
    activeFilterValue: 24,
    active: 0,
    visible: false,
    // startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
    // endValue: moment(new Date(), 'YYYY-MM-DD'),
    // downloadType: 0,
    loading: true,
    caretDownArrow: false,
    rowKey: '',
    expendIdNo: 0,
    participantsData: [],
    expandedRowKeys: 0,
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    searchType: 'search',
    groups: []
  }
  download = () => {
    const {
      analytic: { list }
    } = this.props.data
    let dataCSV = []
    let header = []

    var eventData = []
    const pollData = this.getPollData(list.polls)

    list &&
      eventData.push({
        title: list.title,
        username: '',
        employee_id: '',
        duration: `${this.getTimeStamp(list.start_time)} - ${this.getTimeStamp(
          list.stop_time
        )}`,
        lived_at: moment(list.lived_at).format('YYYY-MM-DD'),
        join_leave: '',
        watched_time: '',
        emptyCol: ''
      })
    if (list.user_data.length) {
      list.user_data
        .filter(item => item.username !== null)
        .forEach(item => {
          eventData.push({
            title: '',
            username: item.username,
            employee_id: item.employee_id,
            duration: '',
            lived_at: '',
            join_leave: `${this.getTimeStamp(
              item.join_time
            )} - ${this.getTimeStamp(item.leave_time)}`,
            watched_time: this.getDuration(item.join_time, item.leave_time),
            emptyCol: ''
          })
        })
    }

    var emptyEventData = {
      title: '',
      username: '',
      employee_id: '',
      duration: '',
      date: '',
      join_leave: '',
      watched_time: '',
      emptyCol: ''
    }

    if (pollData.length) {
      for (let i = 0; i >= 0; i++) {
        pollData.forEach(item => {
          eventData[i] = {
            ...(eventData[i] || emptyEventData),
            emptyCol: '',
            question: item.question
          }
          i++
          const answersArray = item.answers
          if (answersArray) {
            answersArray.forEach(el => {
              const isVotes = el.answer && item.totalVotes
              eventData[i] = {
                ...(eventData[i] || emptyEventData),
                question: '',
                answer: el.answer,
                votes: isVotes
                  ? `${((el.votes / item.totalVotes) * 100)
                      .toFixed(2)
                      .replace(/[.,]00$/, '')} %`
                  : el.answer
                  ? '0 %'
                  : ''
              }
              i++
            })
          }
        })
        break
      }
    }

    dataCSV.push(...eventData)

    header = [
      'Live Event Title',
      'Username',
      'Employee Id',
      'Start/End live',
      'Live Date',
      'Join/Leave',
      'Watched Time',
      '',
      'Poll Questions',
      'Poll Answers',
      'Vote'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'event-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'event-report.csv')
      ele.click()
    }
  }

  getTimeStamp = str => {
    if (!str) return 'N/A'
    return moment(str).format('hh:mm a')
  }

  getDuration = (from, to) => {
    if (!from || !to) return 'N/A'
    var start = moment(from)
    var end = moment(to)
    var duration = moment.duration(end.diff(start))
    console.log(duration.asMinutes().toFixed(2))
    return `${duration.asMinutes().toFixed(2)} minutes`
  }

  getPollData = (data = []) => {
    const pollData = data.map(item => {
      var answerArr = item.answers.map((answer, index) => ({
        answer: answer,
        votes:
          item.votes && item.votes.hasOwnProperty(index) ? item.votes[index] : 0
      }))

      const totalVotes = answerArr.reduce(
        (total, item) => total + item.votes,
        0
      )

      return {
        question: item.question,
        answers: answerArr,
        totalVotes
      }
    })
    return pollData
  }

  select = (e, i) => {
    e.preventDefault()
    this.setState({
      active: i
    })
  }

  handleChange(value) {
    const activeFilterValue = value === '1' ? 24 : Number(value)
    this.setState({ activeFilterValue })
  }

  getColumns(answers, Result) {
    return [
      {
        dataIndex: 'avatar',
        render: x => {
          return <Avatar size={42} src={x} />
        },
        width: 42
      },
      {
        dataIndex: 'username'
      },
      {
        dataIndex: 'vote',
        render: vote => {
          return (
            <span>
              <Avatar
                size={15}
                style={{
                  backgroundColor: Result[vote].itemStyle.color,
                  marginRight: 5
                }}
              />
              {answers[vote]}
            </span>
          )
        }
      },
      {
        dataIndex: 'date',
        render: x => {
          return 'submitted ' + moment(x).format('DD/M/YY, LT')
        }
      }
    ]
  }

  componentDidMount() {
    const {
      match: { params },
      getEventReport
    } = this.props
    getEventReport(params.id, 'analytic').then(data => {
      if (data) {
        this.setState({
          loading: false,
          participantsData: data.user_data
        })
      }
    })
    getEventReport(params.id, 'comment')
    this.fetchGroups()
  }

  onClickRow = key => {
    this.setState(
      {
        rowKey: key
      },
      () => {
        this.setState({
          caretDownArrow: true
        })
      }
    )
  }

  onSearch = val => {
    const {
      analytic: {
        list: { user_data: data = [] }
      }
    } = this.props.data
    if (val === '') {
      this.setState({ participantsData: data })
      return
    }

    const searchResult = data.filter(item => {
      return item.username.toLowerCase().includes(val.toLowerCase())
    })
    this.setState({ participantsData: searchResult })
  }

  onClickCloseIcon = () => {
    this.setState(
      {
        rowKey: ''
      },
      () => {
        this.setState({
          caretDownArrow: false
        })
      }
    )
  }

  getAllComments = userId => {
    const {
      analytic: {
        list: { Comments: data }
      }
    } = this.props.data
    const commentsData = data && data.filter(item => item.id === userId)
    return commentsData
  }

  renderComments = userId => {
    const comments = this.getAllComments(userId)
    return (
      <>
        {comments &&
          comments.map((item, i) => (
            <div className="media" key={i}>
              <img
                alt=""
                className="user-image"
                src={item.avatar === '' ? userImage : item.avatar}
              />
              <h5>
                {item.username} <span className="circle" />{' '}
                {moment(item.date).fromNow()}
              </h5>
              <p>{item.comment}</p>
            </div>
          ))}
      </>
    )
  }

  getNumberOfPolls = userId => {
    const {
      analytic: {
        list: { Votes: data = [] }
      }
    } = this.props.data
    if (data.length === 0) return 0
    const userVotes = data && data.filter(item => item.id === userId)
    if (userVotes.length > 0) return userVotes.length
    return 0
  }

  renderPolls = userId => {
    const {
      analytic: {
        list: { Votes: data = [] }
      }
    } = this.props.data
    if (data.length === 0) return 0
    const userVotes = data && data.filter(item => item.id === userId)
    return (
      <>
        {userVotes &&
          userVotes.map((item, i) => (
            <div className="media" key={i}>
              <h5>
                <b>{item.question} </b>
              </h5>
              <Row>
                <Col span={6}>
                  <ReactEcharts
                    option={{
                      color: ['#2699FB'],
                      tooltip: {},
                      series: [
                        {
                          data: [{ name: item.answer, value: 100 }],
                          type: 'pie',
                          radius: '50%',
                          z: 2
                        }
                      ]
                    }}
                  />
                </Col>
                <Col span={18} />
              </Row>
            </div>
          ))}
      </>
    )
  }

  onExpand = (expanded, record) => {
    if (this.state.expendIdNo===record.id) {
      this.setState({
        expendIdNo: 0
      })
    } else {
      this.setState({
        expendIdNo: record.id
      })
    }
    var keys = []
    if (expanded) {
      keys.push(record.id)
    }
    this.setState({ expandedRowKeys: keys })
  }

  customExpandIcon = props => {
    return this.state.expendIdNo===props.record.id ? (
      <Icon type="up" />
    ) : (
      <Icon type="down" />
    )
  }

  expandEventRow = record => {
    const pollArr = record.polls && record.polls.filter(item => item !== null)
    const totalPolls = pollArr ? pollArr.length : '0'
    const totalComments = record.comments ? record.comments.length : '0'
    const setTimeStamp = time => {
      const timestamp = parseInt(time)
      const date = moment(timestamp)
        .fromNow()
        .toString()
      return date || ''
    }
    return (
      <div className="report-card-container">
        <Tabs type="card">
          <TabPane tab={`Polls (${totalPolls})`} key="1">
            {record.polls &&
              record.polls.length > 0 &&
              record.polls.map((item, i) => (
                <div className="poll_data" key={i}>
                  {item && (
                    <>
                      <h3>Q: {item.question}</h3>
                      <p>A: {item.answer}</p>
                    </>
                  )}
                </div>
              ))}
            {pollArr === 0 && (
              <Alert
                message="Sorry ! No Polls are available for this user."
                type="warning"
                showIcon
              />
            )}
          </TabPane>
          <TabPane tab={`Comments (${totalComments})`} key="2">
            {totalComments > 0 &&
              record.comments.map(item => (
                <div className="user-comment">
                  <img
                    alt=""
                    className="user-image"
                    src={!record.avatar ? userImageNoBack : record.avatar.link}
                  />
                  <div className="user-comment-info">
                    <h5>
                      {item.username} <span className="circle" />{' '}
                      {setTimeStamp(item.time)}
                    </h5>
                    <p>{item.message}</p>
                  </div>
                </div>
              ))}
            {totalComments === 0 && (
              <Alert
                message="Sorry ! No Comments are available for this user."
                type="warning"
                showIcon
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    )
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    this.setState({
      page: page
    })
  }

  onClickImage = () => {
    const {
      analytic: {
        list: { id }
      }
    } = this.props.data
    this.props.history.push(`/events/${id}`)
  }

  handleSearchType = type => {
    const {
      analytic: {
        list: { user_data: data = [] }
      }
    } = this.props.data
    if (type === 'name') {
      this.setState({
        searchType: 'searchByname'
      })
    } else if (type === 'id') {
      this.setState({
        searchType: 'searchById'
      })
    } else if (type === 'email') {
      this.setState({
        searchType: 'searchByemail'
      })
    } else if (type === 'group_name') {
      this.setState({
        searchType: 'searchByGroup'
      })
    } else {
      this.setState({
        searchType: 'search',
        participantsData: data
      })
    }
  }

  onSearchWithType = val => {
    const { searchType, groups } = this.state
    const {
      analytic: {
        list: { user_data: data = [] }
      }
    } = this.props.data

    if (val === '') {
      this.setState({ participantsData: data })
      return
    }

    if (searchType === 'search') {
      const searchResult = data.filter(item => {
        return item.username.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByname') {
      const searchResult = data.filter(item => {
        return item.username.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByGroup' && groups.length > 0) {
      const searchResult = data.filter(item => {
        return (
          item.group && item.group.toLowerCase().includes(val.toLowerCase())
        )
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchById') {
      const searchResult = data.filter(item => {
        return val.includes(item.id)
      })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByemail') {
      const searchResult = data.filter(item => {
        return item.email.toLowerCase().includes(val.toLowerCase())
      })
      this.setState({ participantsData: searchResult })
    }
  }

  handleGroupSearch = value => {
    this.onSearchWithType(value)
  }

  fetchGroups = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    api.organizations.get().then(res => {
      const activeOrg = res.data.rows.filter(
        item => item.id === active_organization_id
      )
      if (activeOrg && activeOrg[0]) {
        this.setState({
          groups: activeOrg[0].groups
        })
      }
    })
  }

  callbackTabClicked = key => {
    if (key === '1') {
      this.props.history.push('/reports', 'user')
    } else if (key === '3') {
      this.props.history.push(`/reports`, 'marketplace')
    }
  }

  render() {
    const {
      loading,
      participantsData,
      expandedRowKeys,
      page,
      pageSize,
      searchType,
      groups
    } = this.state

    const {
      fetch,
      analytic: {
        list: { lived_at, duration, comments, polls, event_cover }
      },
      comment: {
        list: {}
      }
    } = this.props.data
    const { t } = this.props
    const dataList = participantsData.slice(
      this.createOffset(page, pageSize),
      this.createOffset(page, pageSize) + 10
    )

    return (
      <Fragment>
        {fetch || loading ? (
          <Spinner />
        ) : (
          <div className="content-warp">
            <div className="report-warp" style={{ direction: 'ltr' }}>
              <CustomTabs
                defaultActiveKey="2"
                onTabClick={this.callbackTabClicked}
                tabBarExtraContent={
                  <ReportHeader download={this.download} isDateActive />
                }
              >
                <TabPane tab={t('reports:workshop_report')} key="0">
                  <TypeSelect defaultValue="events" />

                  <div className="custom-table">
                    <Row>
                      <Col span={8}>
                        <Row>
                          <BackButton
                            onClick={() =>
                              this.props.history.push('/reports/events/')
                            }
                            size="large"
                            style={{
                              marginTop: '20px',
                              marginLeft: '15px'
                            }}
                          >
                            <LeftOutlined />
                            {t('v4:back')}
                          </BackButton>
                        </Row>
                        <Card
                          className={
                            participantsData.length === 0
                              ? 'custom-card-empty'
                              : 'custom-card'
                          }
                        >
                          <img
                            className="table-img"
                            src={event_cover.link}
                            onClick={this.onClickImage}
                            style={{ cursor: 'pointer' }}
                            alt=""
                          />
                          <p className="custom-description"></p>
                          <Divider
                            type="horizontal"
                            dashed
                            style={{ borderColor: 'rgb(155 155 155)' }}
                          />

                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:live_date').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {moment(lived_at).format('YYYY/MM/DD')}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:participants').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {participantsData.length}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:duration_min').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {duration} {t('v2:mins')}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:comments').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {comments && comments.length}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:submit_poll').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {polls && polls.length}
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col span={16}>
                        <ReportTable
                          rowKey="id"
                          columns={columns(t)}
                          dataSource={dataList}
                          expandedRowRender={record =>
                            this.expandEventRow(record)
                          }
                          onExpand={this.onExpand}
                          expandIcon={this.customExpandIcon}
                          expandIconColumnIndex={7}
                          expandedRowKeys={expandedRowKeys || []}
                          expandIconAsCell={false}
                          expandRowByClick
                          pagination={false}
                          width
                          style={{
                            width: '92%',
                            marginLeft: '35px'
                          }}
                          title={() => (
                            <>
                              <Row
                                style={{ marginLeft: '30px', marginTop: '3px' }}
                              >
                                <Col span={13}>
                                  <div className="reports-user-search">
                                    {searchType === 'searchByGroup' &&
                                      groups.length > 0 && (
                                        <ReportSelect
                                          onChange={this.handleGroupSearch}
                                          className="rounded-select"
                                          dropdownStyle={{
                                            borderRadius: '9px'
                                          }}
                                          arrowColor
                                          defaultValue={t('v4:select_group')}
                                        >
                                          {groups.map(group => {
                                            return (
                                              <Select.Option
                                                key={group.id}
                                                value={group.title}
                                                className="select-option-user"
                                              >
                                                {group.title}
                                              </Select.Option>
                                            )
                                          })}
                                        </ReportSelect>
                                      )}

                                    {(searchType !== 'searchByGroup' ||
                                      (searchType === 'searchByGroup' &&
                                        groups.length === 0)) && (
                                      <Search
                                        placeholder={t('v4:search_people')}
                                        onChange={e =>
                                          this.onSearchWithType(e.target.value)
                                        }
                                      />
                                    )}
                                  </div>
                                </Col>
                                <Col className="report-title" span={5} push={2}>
                                  {t('v4:total')} {participantsData.length}{' '}
                                  {t('reports:participants').toUpperCase()}
                                </Col>
                                <Col span={6} style={{ marginTop: '14px' }}>
                                  <PaginationRightAligned>
                                    <Pagination
                                      total={participantsData.length}
                                      current={page}
                                      pageSize={pageSize}
                                      onChange={this.onChangePagination}
                                      size="small"
                                      simple
                                    />
                                  </PaginationRightAligned>
                                </Col>
                              </Row>
                              <Divider style={{ margin: '0px' }} />
                            </>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab={t('reports:user_report')} key="1"></TabPane>
                <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
              </CustomTabs>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
export default withTranslation('reports')(Preview)
